import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Typography,
  makeStyles,
  Grid,
  MenuItem,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";
import AuthenticateContainer from "./Layout/AuthenticateContainer";
import { Service, errorResponseHandler } from "../Services/Service";
import {
  PrimarySelect,
  PrimaryInput,
  ErrorMessage,
  PrimaryButton,
  PasswordInput,
  DangerButton,
} from "../Component";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { bytesToSize, formateAmount } from "../Helper/Common";
import { StorageKey } from "../Config/StorageKey";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  formControl: {
    width: "100%",
    margin: theme.spacing(0, 0, 2),
  },
}));

const AccountStep = {
  BEGINNING: "beginning",
  OTP: "otp",
  SUCCESS: "success",
  CONFIRMATION: "confirmation",
};

function DeleteAccountPage(props: any) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [reasons, setReason] = React.useState([]);
  const [rawData, setRawData] = React.useState({});
  const [paymentSummary, setPaymentSummary] = React.useState({
    total: 0,
    total_size: 0,
  });

  const [step, setStep] = React.useState(AccountStep.BEGINNING);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const getReason = () => {
    Service.get("reasons/delete")
      .then((response) => {
        setLoading(false);
        setReason(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getFileList = () => {
    Service.get("lastSixMonthFiles?limit=50&offset=0")
      .then((response) => {
        setFiles(response.data.data);
        setPaymentSummary(response.data.fileSummary);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleOtpSubmit = (data: any) => {
    setStep(AccountStep.CONFIRMATION);
    setRawData({
      ...rawData,
      otp: data.otp,
    });
  };

  const onSubmit = (data: any) => {
    setRawData({
      password: data.password,
      reason_id: data.reason,
      feedback: data.detail,
    });
    if ([true, 1, 2].indexOf(props.user.is_two_factor_enable) !== -1) {
      setLoading(true);
      deleteAccount({
        password: data.password,
        reason_id: data.reason,
        feedback: data.detail,
      });
    } else {
      setStep(AccountStep.CONFIRMATION);
    }
  };

  const deleteAccount = (payload: any) => {
    setLoading(true);
    Service.post("deleteAccount", payload)
      .then((response) => {
        setLoading(false);
        if (response.data?.is_two_factor_enable) {
          setStep(AccountStep.OTP);
          return;
        }
        setStep(AccountStep.SUCCESS);
        localStorage.removeItem(StorageKey.USER);
      })
      .catch((error) => {
        setLoading(false);
        setStep(AccountStep.BEGINNING);
        if (errorResponseHandler(error, enqueueSnackbar)) {
          enqueueSnackbar(error.response.data.message, {
            key: new Date().getTime() + Math.random(),
            variant: "error",
          });
        }
      });
  };

  React.useEffect(() => {
    getReason();
    getFileList();
  }, []);

  const cancelProcess = (event: any) => {
    event.preventDefault();
    history.push({
      pathname: "/profile",
    });
  };

  const confirmationScreen = () => {
    return (
      <>
        <Typography gutterBottom>
          You are about to delete your account. You will no longer have access
          to any of your data. Are you sure you want to do this?
        </Typography>
        <Box mt={5} />

        <Grid container justify="flex-end" spacing={2} xs={12}>
          <Box className="btn-together">
            <PrimaryButton
              type="button"
              variant="outlined"
              color="primary"
              className="smFullWidth"
              onClick={cancelProcess}
            >
              Cancel
            </PrimaryButton>
            <Box pl={1} />
            <DangerButton
              type="submit"
              variant="contained"
              color="primary"
              className="smFullWidth"
              disabled={loading}
              loading={loading}
              onClick={() => deleteAccount(rawData)}
            >
              Yes, delete my account
            </DangerButton>
          </Box>
        </Grid>
      </>
    );
  };

  const confirmationWithFileScreen = () => {
    return (
      <>
        <Typography gutterBottom>
          Some of your files have been stored for less than the minimum storage
          period. You can still delete your account, but will be charged for the
          remaining days for each file that has not met the minimum storage
          period.
        </Typography>
        <Box mt={2} />

        <Typography gutterBottom>
          Below is a list of all the files you will be charged for if you delete
          your account now.
        </Typography>
        <Box mt={2} />

        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>File</b>
              </TableCell>
              <TableCell>
                <b>Size</b>
              </TableCell>
              <TableCell>
                <b>Billable Days</b>
              </TableCell>
              <TableCell>
                <b>Cost</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map((file: any) => {
              return (
                <TableRow>
                  <TableCell>{file.name}</TableCell>
                  <TableCell>{bytesToSize(file.size)}</TableCell>
                  <TableCell>{file.remaining_days || 0}</TableCell>
                  <TableCell>${formateAmount(file.cost)}</TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell colSpan={3}>
                <b>Total charges</b>
              </TableCell>
              <TableCell>${formateAmount(paymentSummary.total)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Box mt={2} />

        <Typography gutterBottom>
          Are you sure you want to delete your account? You will no longer have
          access to any of your data. A total of $0.11 will be charged to your
          credit card.
        </Typography>
        <Box mt={2} />

        <Grid container justify="flex-end" spacing={2}>
          <Box className="btn-together">
            <PrimaryButton
              type="button"
              variant="outlined"
              color="primary"
              className="smFullWidth"
              onClick={cancelProcess}
            >
              Cancel
            </PrimaryButton>
            <Box pl={1} />
            <DangerButton
              type="submit"
              variant="contained"
              className="smFullWidth"
              disabled={loading}
              loading={loading}
              onClick={() => deleteAccount(rawData)}
            >
              Yes, delete my account
            </DangerButton>
          </Box>
        </Grid>
      </>
    );
  };

  const otpScreen = () => {
    return (
      <>
        <Typography gutterBottom>
          Enter the 6-digit code generated by your authenticator app.
        </Typography>
        <Box mt={3} />
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(handleOtpSubmit)}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
            xs={12}
          >
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="first_name" className="form-label">
                  6-digit Code
                </InputLabel>

                <PrimaryInput
                  id="otp"
                  inputProps={{
                    maxLength: 6,
                  }}
                  style={{ width: "150px" }}
                  {...register("otp", {
                    required: true,
                  })}
                />
                <ErrorMessage errors={errors.otp} />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2} justify="flex-end">
                <Grid item xs={6} md={3}>
                  <PrimaryButton
                    fullWidth
                    type="button"
                    variant="outlined"
                    color="primary"
                    onClick={cancelProcess}
                  >
                    Cancel
                  </PrimaryButton>
                </Grid>
                <Grid item xs={6} md={3}>
                  <PrimaryButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    loading={loading}
                  >
                    Continue
                  </PrimaryButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  };

  const beginning = () => {
    return (
      <>
        <Typography gutterBottom>
          Are you sure you want to delete your account? You will no longer have
          access to any of your data
        </Typography>
        <Typography gutterBottom>
          Please enter your password to confirm
        </Typography>
        <Box mt={3} />
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
            xs={12}
          >
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="password" className="form-label">
                  Password
                </InputLabel>
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    maxLength: 100,
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <PasswordInput
                      placeholder="Password"
                      id="password"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                <ErrorMessage errors={errors.password} />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="last_name" className="form-label">
                  Reason for deleting account
                </InputLabel>

                <PrimarySelect
                  id="reason"
                  displayEmpty
                  disableUnderline
                  defaultValue=""
                  {...register("reason", {
                    required: true,
                  })}
                >
                  <MenuItem value="">Select reason</MenuItem>
                  {reasons.map((reason: any) => (
                    <MenuItem value={reason.id}>{reason.reason}</MenuItem>
                  ))}
                </PrimarySelect>

                <ErrorMessage errors={errors.reason} />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="detail" className="form-label">
                  Detals (optional)
                </InputLabel>
                <PrimaryInput
                  placeholder="Tell us more about why Alpii didn't work for you"
                  id="detail"
                  {...register("detail")}
                  multiline
                  rows={4}
                />
                <ErrorMessage errors={errors.phone_number} />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2} justify="flex-end">
                <Grid item xs={6} md={3}>
                  <PrimaryButton
                    fullWidth
                    type="button"
                    variant="outlined"
                    color="primary"
                    onClick={cancelProcess}
                  >
                    Cancel
                  </PrimaryButton>
                </Grid>
                <Grid item xs={6} md={3}>
                  <PrimaryButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    loading={loading}
                  >
                    Continue
                  </PrimaryButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  };

  const successStep = () => {
    return (
      <>
        <Typography gutterBottom>
          We’re sorry to see you go. We would love if you could take some time
          to{" "}
          <Link to={"/feedback"} className="primary">
            leave us some feedback
          </Link>
          —we’re always looking for ways to improve.
        </Typography>
      </>
    );
  };

  const confirmationContent = () => {
    if (props.user.is_subscribe === 1 && paymentSummary.total >= 0.50) {
      return confirmationWithFileScreen();
    }
    return confirmationScreen();
  };

  return (
    <AuthenticateContainer sidebar="none">
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={10} sm={6} md={5} xl={6} lg={5}>
          <Typography variant="h6" gutterBottom>
            <b>
              {" "}
              {step === AccountStep.SUCCESS
                ? "Your Account Has Been Deleted"
                : "Delete Account"}
            </b>
          </Typography>
          <Box mt={3} />
          {step === AccountStep.BEGINNING && beginning()}
          {step === AccountStep.OTP && otpScreen()}
          {step === AccountStep.CONFIRMATION && confirmationContent()}
          {step === AccountStep.SUCCESS && successStep()}
        </Grid>
      </Grid>
    </AuthenticateContainer>
  );
}

const mapStateToProps = (state: any) => {
  return {
    loader: state.loader,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUser: (user: any) => dispatch({ type: "SET_LOGGIN_USER", user: user }),
    setPopup: (dialog: string) => dispatch({ type: "SET_POPUP", name: dialog }),
    setConfirmationPopup: (dialogObject: any) =>
      dispatch({ type: "SET_DIALOG_OBJECT", dialogObject: dialogObject }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccountPage);
