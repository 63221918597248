import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import PrimaryButton from "../Component/PrimaryButton";
import PrimaryInput from "../Component/PrimaryInput";
import UnauthenticateContainer from "./Layout/UnauthenticateContainer";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { validEmail } from "../Library/Validation";
import ErrorMessage from "../Component/ErrorMessage";
import { Service } from "../Services/Service";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    // alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(0, 0, 2),
  },
  or: {
    textAlign: "center",
    borderBottom: "2px solid rgb(245 239 239)",
    lineHeight: "0.1em",
    margin: "25px 0",
    width: "100%",
    "& > span": {
      background: "#fff",
      padding: "0 10px",
    },
  },
  formControl: {
    width: "100%",
    margin: theme.spacing(0, 0, 2),
  },
  socialBtn: {
    borderRadius: "10px",
    fontSize: "16px",
    textTransform: "none",
    "& img": {
      marginRight: "10px",
    },
  },
}));

export default function ForgotPassword() {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    setLoading(true);
    Service.post("forgot/password", {
      email: data.email,
    })
      .then((response: any) => {
        history.push(`forgot-password-success`);
      })
      .catch((error: any) => {
        history.push(`forgot-password-success`);
      });
  };

  return (
    <UnauthenticateContainer>
      <Box p={2} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h4" align="left">
            <b>Reset your password</b>
          </Typography>
          <Box p={1} />
          <Typography variant="h6" color="textSecondary">
            Please enter your email below. We'll send you a link to reset your
            password.
          </Typography>
          <Box p={1} />
          <Typography variant="h6" color="textSecondary">
            Already know your email?&nbsp;
            <Link to={"login"} className="primary">
              Sign in
            </Link>
          </Typography>

          <Box p={2} />

          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="email" className="form-label">
                Email
              </InputLabel>
              <PrimaryInput
                placeholder="Email"
                id="email"
                {...register("email", {
                  required: true,
                  validate: {
                    email: (value: string) => validEmail(value),
                  },
                })}
              />
              <ErrorMessage errors={errors.email} />
            </FormControl>

            <PrimaryButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              loading={loading}
              className={classes.submit}
            >
              Reset
            </PrimaryButton>
          </form>
        </div>
      </Container>
    </UnauthenticateContainer>
  );
}
