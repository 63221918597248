import { Fab, Box } from "@material-ui/core";

function FloatingButton(props: any) {
  return (
    <Box className="floating-button">
      <Fab
        color="primary"
        aria-label="add"
        onClick={() => props.onClick()}
      >
        +
      </Fab>
    </Box>
  );
}

export default FloatingButton;
