import React from "react";
import InputBase from "@material-ui/core/InputBase";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem, Select } from "@material-ui/core";
import usaIcon from "../images/country/usa.png";

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "1px solid #ced4da",
    borderRadius: "10px",
    padding: "3px 12px",
    marginTop: "23px",
  },
  icon: {
    display: "none",
  },
  selectRoot: {
    paddingRight: "5px !important",
    borderRight: "solid 1px #cccccc",
    marginRight: "5px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: '60px',
  },
  menuItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default function MobileInput(props: any) {
  const classes = useStyles();

  const onCountryChange = (e: any) => {
    if(props.onCountryChange) {
      props.onCountryChange(e);
    }
  }

  return (
    <InputBase
      {...props}
      classes={{
        root: classes.paper,
      }}
      startAdornment={
        <Select
          id="demo-simple-select"
          label="Country Code"
          classes={{ icon: classes.icon, root: classes.selectRoot }}
          disableUnderline
          defaultValue={props.defaultCountryCode || "1"}
          onChange={onCountryChange}
        >
          <MenuItem value={1} classes={{ root: classes.menuItem }}>
            <img src={usaIcon} alt="usa" />
            &nbsp; +1
          </MenuItem>
          <MenuItem value={91} classes={{ root: classes.menuItem }}>
            <img src={usaIcon} alt="india" />
            &nbsp; +91
          </MenuItem>
        </Select>
      }
    />
  );
}
