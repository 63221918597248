import React, { useRef } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { useForm, Controller } from "react-hook-form";
import PasswordInput from "./PasswordInput";
import ErrorMessage from "./ErrorMessage";
import PrimaryButton from "./PrimaryButton";
import { Service, errorResponseHandler } from "../Services/Service";

const ChangePasswordDialog = (props: any) => {
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const new_password = useRef({});
  new_password.current = watch("new_password", "");

  const handleClose = () => {
    props.setPopup("");
    reset({
      old_password: "",
      new_password: "",
      confirm_password: "",
    });
  };

  const onSubmit = (data: any) => {
    setLoading(true);
    Service.post("change/password", data)
      .then((response) => {
        setLoading(false);
        handleClose();
        enqueueSnackbar(response.data.message, {
          key: new Date().getTime() + Math.random(),
          variant: "success",
        });
      })
      .catch((error) => {
        setLoading(false);
        if (errorResponseHandler(error, enqueueSnackbar)) {
          enqueueSnackbar(error.response.data.message, {
            key: new Date().getTime() + Math.random(),
            variant: "error",
          });
        }
      });
  };

  return (
    <Dialog
      scroll="body"
      fullWidth={true}
      maxWidth={"sm"}
      open={props.dialog === "change_password"}
      onClose={handleClose}
    >
      <DialogTitle>
        <Typography variant="h3">Change Password</Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box mt={2} />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl>
                <InputLabel
                  shrink
                  htmlFor="old_password"
                  className="form-label"
                >
                  Current Password
                </InputLabel>

                <Controller
                  name="old_password"
                  control={control}
                  rules={{
                    maxLength: 100,
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <PasswordInput
                      placeholder="Current Password"
                      id="old_password"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                <ErrorMessage errors={errors.old_password} />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <InputLabel
                  shrink
                  htmlFor="new_password"
                  className="form-label"
                >
                  New Password
                </InputLabel>

                <Controller
                  name="new_password"
                  control={control}
                  rules={{
                    maxLength: 100,
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <PasswordInput
                      placeholder="New Password"
                      id="new_password"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                <ErrorMessage errors={errors.new_password} />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl>
                <InputLabel
                  shrink
                  htmlFor="confirm_password"
                  className="form-label"
                >
                  Confirm Password
                </InputLabel>

                <Controller
                  name="confirm_password"
                  control={control}
                  rules={{
                    maxLength: 100,
                    required: true,
                    validate: {
                      match: (value: string) => value === new_password.current,
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <PasswordInput
                      placeholder="Confirm Password"
                      id="confirm_password"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors.confirm_password}
                  label="new password"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Box className="btn-together">
                <PrimaryButton
                  className="smFullWidth"
                  variant="outlined"
                  onClick={handleClose}
                >
                  Cancel
                </PrimaryButton>
                <Box pl={1} />
                <PrimaryButton
                  className="smFullWidth"
                  type="submit"
                  loading={loading}
                  disable={loading}
                >
                  Change Password
                </PrimaryButton>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </form>
    </Dialog>
  );
};

const mapStateToProps = (state: any) => {
  return {
    dialog: state.dialog,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPopup: (name: string) => dispatch({ type: "SET_POPUP", name: name }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordDialog);
