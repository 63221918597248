import { initiateDeleteFile, forceDeleteFile } from "./file";
import { initiateDeleteFolder, forceDeleteFolder } from "./folder";

export const initiateDeleteResource = (resourceObject) => {
  return (dispatch) => {
    if (resourceObject?.type === "file") {
      dispatch(initiateDeleteFile(resourceObject));
    } else {
      dispatch(initiateDeleteFolder(resourceObject));
    }
  };
};

export const forceDeleteResource = (resourceObject) => {
  return (dispatch) => {
    if (resourceObject?.type === "file") {
      dispatch(forceDeleteFile(resourceObject));
    } else {
      dispatch(forceDeleteFolder(resourceObject));
    }
    return Promise.resolve();
  };
};
