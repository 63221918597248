import * as React from "react";
import PaginateComponent from "../Component/PaginateComponent";
import { formateDate } from "../Helper/Common";

function WhitelistPage(props: any) {
  const headCells = [
    {
      id: "whitelist_id",
      numeric: false,
      disablePadding: true,
      align: "left",
      label: "ID",
      beautify: (row: any) => {
        return row.id;
      },
    },
    {
      id: "rating",
      numeric: false,
      disablePadding: false,
      align: "left",
      label: "Rating",
      beautify: (row: any) => {
        return row.rating;
      },
    },
    {
      id: "feedback",
      numeric: false,
      disablePadding: false,
      align: "left",
      label: "Feedback",
      beautify: (row: any) => {
        return row.feedback;
      },
    },
    {
      id: "given_at",
      numeric: false,
      disablePadding: false,
      align: "right",
      label: "Given at",
      beautify: (row: any) => {
        return formateDate(new Date(row.created_at));

      },
    },
  ];

  return (
    <PaginateComponent
      apiPrefix="feedback"
      component="feedback"
      headCells={headCells}
      title={"Feedback"}
    />
  );
}

export default WhitelistPage;
