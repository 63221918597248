import { MuiThemeProvider, makeStyles } from "@material-ui/core/styles";
import { Box, Container, Typography } from "@material-ui/core";
import FullHeader from "./FullHeader";
import themeLight from "./theme";

const useStyles = makeStyles((theme) => ({
  footer: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  menuitem: {
    marginRight: 50,
    marginTop: "5px",
    marginBottom: "5px",
  },
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginLeft: 0,
  },
}));

function Footer() {
  const classes = useStyles();
  return (
    <Box
      component="footer"
      sx={{
        py: 2,
        mt: "auto",
      }}
    >
      <Container className={classes.container}>
        <Box className={classes.footer}>
          <Typography
            variant="body2"
            className={`dark-grey ${classes.menuitem}`}
          >
            <a
              href="https://alpii.com"
              target="_blank"
              rel="noreferrer"
              className="unstyled"
            >
              © 2022 Alpii LLC
            </a>
          </Typography>
          <Typography
            variant="body2"
            className={`dark-grey ${classes.menuitem}`}
            style={{ marginRight: 50 }}
          >
            <a
              href="https://alpii.com/privacy"
              target="_blank"
              rel="noreferrer"
              className="unstyled"
            >
              Privacy
            </a>
          </Typography>
          <Typography
            variant="body2"
            className={`dark-grey ${classes.menuitem}`}
          >
            <a
              href="https://alpii.com/terms"
              target="_blank"
              rel="noreferrer"
              className="unstyled"
            >
              Terms
            </a>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default function UnauthenticateContainer(props: any) {
  return (
    <MuiThemeProvider theme={themeLight}>
      <FullHeader />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container component="main">{props.children}</Container>
        <Footer />
      </Box>
    </MuiThemeProvider>
  );
}
