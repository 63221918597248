import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
} from "@material-ui/core";
import { connect } from "react-redux";
import { PrimaryButton } from "../Component";
import * as Icon from "../Icon/index";

const UpdateCreditCardWarning = (props: any) => {
  const handleClose = () => {
    props.setPopup("");
  };

  return (
    <Dialog
      id="update_credit_card_warning"
      scroll="body"
      fullWidth={true}
      open={props.dialog === "UpdateCreditCardWarning"}
      onClose={handleClose}
      className="dark-mode"
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography color="error" variant="h3" style={{ display: "flex" }}>
              <Icon.Info className="danger-icon" height="24" width="24" />{" "}
              &nbsp; Please update your payment method
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={handleClose}>
              <Icon.Cancel className="white-icon" />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={5}>
              <Typography>
                There was an issue processing your payment. You must update your
                payment method before you can perform actions such as uploading,
                retrieving or downloading files or folders. Please update your
                payment method.
              </Typography>
            </Box>
            <Box className="btn-together">
              <PrimaryButton
                onClick={handleClose}
                className="smFullWidth type-2"
                variant="outlined"
              >
                Cancel
              </PrimaryButton>
              <Box ml={1} />
              <PrimaryButton
                onClick={() => props.setPopup("payment_method")}
                variant="contained"
                className="smFullWidth type-2"
              >
                Update your payment method
              </PrimaryButton>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    dialog: state.dialog,
    data: state.dialogObject,
    subscription: state.subscription,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPopup: (name: string) =>
      dispatch({ type: "SET_DIALOG_OBJECT", dialogObject: { name: name } }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateCreditCardWarning);
