import axios from "axios";

let token = '';
const httpClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/`,
});

httpClient.interceptors.request.use(function (config) {
  let user: any = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
    config.headers.Authorization = `Bearer ${user.token}`;
    config.headers.token = `${user.token}`;
    token = user.token;
  }
  return config;
});

const CancelToken = axios.CancelToken;

const errorResponseHandler = (error: any, enqueueSnackbar: any) => {
  if (!error.response) {
    enqueueSnackbar("Oops! Looks like you have slow internet connection", {
      key: new Date().getTime() + Math.random(),
      variant: "error",
    });
    return false;
  }
  return true;
};

export { token, CancelToken, errorResponseHandler, httpClient as Service };
