import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import UnauthenticateContainer from "./Layout/UnauthenticateContainer";

const useStyles = makeStyles((theme) => ({
  innerContainer: {
    minHeight: "calc(100vh - 50px)",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ForgotPasswordSuccess() {
  const classes = useStyles();

  return (
    <UnauthenticateContainer>
      <Box p={2} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.innerContainer}
        >
          <div className={classes.paper}>
            <Typography component="h1" variant="h4" align="left">
              <b>Reset your password</b>
            </Typography>
            <Box p={1} />
            <Typography variant="h6" color="textSecondary">
              You’re one step closer to getting back into your account!
            </Typography>
            <Box p={1} />

            <Typography variant="h6" color="textSecondary">
              If an account exists, you will receive an email with a link to
              reset your password.
            </Typography>
          </div>
        </Grid>
      </Container>
    </UnauthenticateContainer>
  );
}
