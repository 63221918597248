import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Link
} from "@material-ui/core";
import PrimaryButton from "./PrimaryButton";
import DangerButton from "./DangerButton";
import moment from "moment";
import * as Icon from "../Icon/index";

const ConfirmationDialog = (props: any) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle>
        <Typography variant="h3" style={{ display: "flex" }}>
          <Icon.Info className="danger-icon" height="24" width="24" />
          &nbsp; Permanently Delete
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to permanently delete this file?
        </Typography>
        {moment(props.data?.created_at).diff(moment(), "days") < 160 && (
          <Box mt={2}>
            <Typography>
              <b>Note:</b> This file has been stored for less than 6 months. You
              will still be billed for the remainder of the 6 month minimum
              storage period even after it has been deleted.
            </Typography>
            <Box mt={2} />
            <Link
              target="_blank"
              href="https://alpii.com/faq#why-six-month-storage-minimum"
            >
              <Typography className="make-link">
                Why is there a 6 month storage minimum?
              </Typography>
            </Link>
          </Box>
        )}
      </DialogContent>
      <Box mb={2} pr={2} mt={2}>
        <DialogActions>
          <Box className="btn-together">
            <PrimaryButton className="smFullWidth" variant="outlined" onClick={props.handleClose}>
              Cancel
            </PrimaryButton>
            <Box pl={1} />
            <DangerButton className="smFullWidth" onClick={props.onConfirm} loading={props.loading}>
              Permanently Delete
            </DangerButton>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ConfirmationDialog;
