import * as React from "react";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import { TableRow } from "@material-ui/core";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Pagination from "@material-ui/lab/Pagination";
import * as Icons from "../Icon";
import { connect } from "react-redux";
import { Service } from "../Services/Service";

interface Data {
  calories: number;
  carbs: number;
  fat: number;
  name: string;
  protein: number;
}

type Order = "asc" | "desc";

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: any;
  enableSerialNumber: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, headCells } =
    props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {props.enableSerialNumber && (
          <TableCell key={"sn"} padding={"none"}>
            <TableSortLabel>S.No</TableSortLabel>
          </TableCell>
        )}
        {headCells.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              // onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span">
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  title: string;
  numSelected: number;
  deleteSelected: any;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected, title, deleteSelected } = props;

  return (
    <Toolbar>
      {numSelected > 0 ? (
        <Typography color="inherit" variant="subtitle1" component="div" style={{ flex: '1 1 100%' }}>
          {numSelected} selected
        </Typography>
      ) : (
        <Typography variant="h6" id="tableTitle" component="div"  style={{ flex: '1 1 100%' }}>
          {title}
        </Typography>
      )}
      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton onClick={deleteSelected}>
            <Icons.Trash />
          </IconButton>
        </Tooltip>
      ) }
    </Toolbar>
  );
};

function PaginateComponent(props: any) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("calories");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(1);
  const [data, setData] = React.useState([]);
  const [paginate, setPaginate] = React.useState({
    total: 0,
  });

  const getData = () => {
    Service.get(`${props.apiPrefix}?page=${page}`)
      .then((response) => {
        setData(response.data.records);
        setPaginate({
          total: response.data.total,
        });
      })
      .catch((error) => {});
  }
  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, props.triggerRefresh]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = props.users.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handlePageChange = (event: any, pageNo: number) => {
    setPage(pageNo);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const deleteSelected = () => {
    Service.delete(`${props.apiPrefix}?ids=${selected}`)
      .then((response) => {
        setSelected([]);
        getData();
      })
      .catch((error) => {});
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Paper>
        <EnhancedTableToolbar
          title={props.title}
          numSelected={selected.length}
          deleteSelected={deleteSelected}
        />
        <TableContainer>
          <Table aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.users.length}
              enableSerialNumber={props.enableSerialNumber}
              headCells={props.headCells}
            />
            <TableBody>
              {data.map((row: any, index: number) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.name}
                    selected={isItemSelected}
                    onClick={(event) => handleClick(event, row.id)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}

                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>

                    {props.enableSerialNumber && (
                      <TableCell key={"sn"} padding={"none"}>
                        {(index + 1) + ((page -1) * 10)}
                      </TableCell>
                    )}

                    {props.headCells.map((headCell: any) => (
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        align={headCell.align}
                        padding={headCell.disablePadding ? "none" : "normal"}
                      >
                        {headCell.beautify(row)}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
              {data.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={6}>There is no record</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={2} pb={2}>
          <Pagination
            count={Math.ceil(paginate.total / 10)}
            showFirstButton
            showLastButton
            variant="outlined"
            shape="rounded"
            onChange={handlePageChange}
          />
        </Box>
      </Paper>
    </Box>
  );
}

const mapStateToProps = (state: any) => {
  return {
    paginate: state.paginate,
    users: state.users,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PaginateComponent);
