import { Service } from "../Services/Service";
import { onDeleteCloseRightSidebar } from "./Other";

export const initiateDeleteFolder = (fileObject) => {
  return (dispatch) => {
    Service.delete(`folders/${fileObject?.id}`)
      .then((response) => {
        dispatch(removeFolderFromList(fileObject.id));
        dispatch(onDeleteCloseRightSidebar(fileObject.id));
      })
      .catch((error) => {});
  };
};

export const forceDeleteFolder = (fileObject) => {
  return (dispatch) => {
    Service.patch(`folders/${fileObject?.id}`, { type: "delete" })
      .then((response) => {
        dispatch(removeFolderFromList(fileObject.id));
        dispatch(onDeleteCloseRightSidebar(fileObject.id));
      })
      .catch((error) => {});
  };
};

export const removeFolderFromList = (ids) => {
  return {
    type: "REMOVE_FOLDER_FROM_LIST",
    ids,
  };
};
