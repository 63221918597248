import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import UnauthenticateContainer from './Layout/UnauthenticateContainer';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  or: {
    textAlign: 'center',
    borderBottom: '2px solid rgb(245 239 239)',
    lineHeight: '0.1em',
    margin: '25px 0',
    width: '100%',
    "& > span": {
      background: '#fff',
      padding: '0 10px'
    }
  },
  formControl: {
    width: '100%',
    margin: theme.spacing(0, 0, 2),
  },
  socialBtn: {
    borderRadius: '10px',
    fontSize: '16px',
    textTransform: 'none',
    "& img": {
      marginRight: '10px'
    }
  }
}));

export default function StorageMinimumTime() {
  const classes = useStyles();

  return (
    <UnauthenticateContainer>
      <Box p={2} />
      <Container component="main">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5" align="left">
            <b>Why is there a 6 month storage minimum?</b>
          </Typography>
          <Box p={1} />
            <Typography variant="subtitle1" align="left">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer fermentum a sapien sed luctus. Nunc interdum diam dapibus massa fringilla, quis luctus nulla auctor. Duis nibh enim, eleifend hendrerit eros quis, consequat elementum dui. Donec mollis, tellus sit amet dapibus malesuada, enim mi dictum turpis, vel porta turpis justo ultricies diam. Ut vel fringilla elit. Nam vel magna fermentum, dapibus ipsum vitae, feugiat orci. Sed hendrerit euismod est, eu ornare metus. Duis sit amet magna odio. Curabitur sapien ante, congue et maximus id, suscipit vel ante. Aenean sagittis pharetra sem nec eleifend. Vestibulum magna nisi, maximus a maximus sit amet, venenatis in metus. Nam vitae commodo nisl, non facilisis elit. Pellentesque vel facilisis ipsum. Etiam ac porta libero.
            </Typography>
            <Box p={1} />
            <Typography variant="subtitle1" align="left">
                Fusce vitae eros mi. Integer quis nisi vel lorem volutpat rutrum vel sed augue. Mauris ac iaculis nisl. Donec et eleifend nisl. Suspendisse vitae facilisis urna. Integer eu neque ac tellus porta malesuada. Etiam feugiat diam id molestie laoreet. Donec iaculis tempus turpis, nec lacinia erat iaculis vitae. Donec rhoncus velit quis augue egestas vulputate. Nulla facilisi. Duis id viverra urna, eget imperdiet justo. Pellentesque mollis neque in faucibus sollicitudin. Pellentesque at est nibh. Pellentesque efficitur ante at massa lobortis aliquet. Praesent mattis suscipit lorem, a faucibus nulla scelerisque et.
            </Typography>
            <Box p={1} />
            <Typography variant="subtitle1" align="left">
                Quisque efficitur nulla a felis facilisis, sed dictum leo tempus. Donec auctor nulla pulvinar rutrum efficitur. Vestibulum vel tellus est. Donec orci risus, sagittis eu finibus at, pretium in nisi. Duis maximus arcu vel ligula tincidunt mollis. Duis et commodo risus. Integer tristique sem a dui pellentesque, non congue mi ultrices. Cras vel ipsum a quam interdum malesuada. Pellentesque sodales commodo dapibus. Etiam dignissim, odio a iaculis consectetur, odio elit dignissim tortor, in accumsan mauris leo id arcu.
            </Typography>
            <Box p={1} />
            <Typography variant="subtitle1" align="left">
                Fusce malesuada, justo non suscipit ultrices, diam eros aliquam nunc, condimentum sodales sem lectus ut dui. Donec tempor, purus eget ullamcorper faucibus, urna odio pharetra nisl, id tincidunt lacus nibh vel tellus. Mauris dignissim sem orci, vitae condimentum neque feugiat a. Proin sit amet velit at lacus cursus congue eget et turpis. Mauris sollicitudin elit tellus, a egestas dui consequat in. Aenean interdum ut libero ut feugiat. Sed quis felis non nisl malesuada hendrerit. Praesent vel justo nulla. In vehicula arcu at augue tincidunt, at congue mauris ullamcorper. Maecenas sed placerat dui. Ut libero tortor, euismod eu tellus a, viverra lacinia sapien. Vivamus sed maximus ante.
            </Typography>
        </div>
      </Container>
    </UnauthenticateContainer>
  );
}
