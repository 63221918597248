import React from "react";
import PropTypes from "prop-types";
import PrimaryButton from "../Component/PrimaryButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Box, Grid, InputLabel, Typography } from "@material-ui/core";
import MobileInput from "../Component/MobileInput";
import PrimaryInput from "../Component/PrimaryInput";
import ErrorMessage from "../Component/ErrorMessage";
import { Service, errorResponseHandler } from "../Services/Service";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import ChangePasswordDialog from "../Component/ChangePasswordDialog";

const TwoFAType = {
  SelectType: "SelectType",
  EnterMobile: "EnterMobile",
  EnterOTP: "EnterOTP",
  QRCode: "QRCode",
  Enabled: "Enabled",
} as const;

function SecurityPage(props: any) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [twoFAStep, set2FAStep] = React.useState("SelectType");
  const [twoFAError, setTwoFAError] = React.useState({});
  const [mobileNumber, setMobileNumber] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("1");
  const [otp, setOTP] = React.useState("");
  const [twoFactorAuthentication, setTwoFactorAuthentication] =
    React.useState("textMessage");
  const [qrCodeSrc, setQrCodeSrc] = React.useState("");
  const [authenticatorKey, setAuthenticatorKey] = React.useState("");
  const [manualCode, setManualCode] = React.useState(false);

  const handleClickOpen = () => {
    setOpen("enablePop");
    setLoading(false);
    set2FAStep(TwoFAType.SelectType);
  };

  const disable2FADisable = () => {
    if (props.user.is_two_factor_enable === 2) {
      setOpen("disablePop");
      set2FAStep(TwoFAType.EnterOTP);
      return;
    }

    setLoading(true);
    Service.post("twoFactor/generateText", {
      country_code: props.user.country_code,
      phone_number: props.user.phone_number,
    })
      .then((response) => {
        setLoading(false);
        setOpen("disablePop");
      })
      .catch((error) => {
        setLoading(false);
        if (errorResponseHandler(error, enqueueSnackbar)) {
          enqueueSnackbar(error.response.data.message, {
            key: new Date().getTime() + Math.random(),
            variant: "error",
          });
        }
      });
  };

  const handleClose = () => {
    setOpen("");
  };

  const disable2FA = () => {
    setTwoFAError({});
    if (otp.trim().length === 0) {
      setTwoFAError({ type: "required" });
    } else if (isNaN(parseInt(otp.trim()))) {
      setTwoFAError({ type: "number" });
    } else if (otp.trim().length === 6) {
      setLoading(true);

      let serviceUrl = "twoFactor/verifyText/disable";
      if (props.user.is_two_factor_enable === 2) {
        serviceUrl = "twoFactor/verifyAuthenticator/disable";
      }

      Service.post(serviceUrl, {
        token: otp.trim(),
        country_code: props.user.country_code,
        phone_number: props.user.phone_number,
      })
        .then((response) => {
          set2FAStep(TwoFAType.Enabled);
          setLoading(false);
          props.setLoggedinUser({
            ...props.user,
            is_two_factor_enable: 0,
          });
        })
        .catch((error) => {
          setLoading(false);
          if (errorResponseHandler(error, enqueueSnackbar)) {
            enqueueSnackbar(error.response.data.message, {
              key: new Date().getTime() + Math.random(),
              variant: "error",
            });
          }
        });
    } else {
      setTwoFAError({ type: "matchLengthNumber" });
    }
  };

  const isValidOTP = () => {
    let type = "";
    if (otp.trim().length === 0) {
      type = "number";
    } else if (isNaN(parseInt(otp.trim()))) {
      type = "number";
    } else if (otp.trim().length !== 6) {
      type = "matchLengthNumber";
    }
    setTwoFAError({ type: type });
    return type.length === 0;
  };

  const next2FA = () => {
    setTwoFAError({ type: "" });
    if (twoFactorAuthentication === "textMessage") {
      if (twoFAStep === TwoFAType.SelectType) {
        set2FAStep(TwoFAType.EnterMobile);
      } else if (twoFAStep === TwoFAType.EnterMobile) {
        if (mobileNumber.trim().length === 0) {
          setTwoFAError({ type: "required" });
        } else if (isNaN(parseInt(mobileNumber.trim()))) {
          setTwoFAError({ type: "number" });
        } else if (mobileNumber.trim().length === 10) {
          setLoading(true);
          Service.post("twoFactor/generateText", {
            country_code: countryCode,
            phone_number: mobileNumber.trim(),
          }).then((response) => {
            set2FAStep(TwoFAType.EnterOTP);
            setLoading(false);
          });
        } else {
          setTwoFAError({ type: "matchLengthNumber" });
        }
      } else if (twoFAStep === TwoFAType.EnterOTP) {
        if (otp.trim().length === 0) {
          setTwoFAError({ type: "required" });
        } else if (isNaN(parseInt(otp.trim()))) {
          setTwoFAError({ type: "number" });
        } else if (otp.trim().length === 6) {
          setLoading(true);
          Service.post("twoFactor/verifyText/enable", {
            token: otp.trim(),
            country_code: countryCode,
            phone_number: mobileNumber.trim(),
          })
            .then((response) => {
              props.setLoggedinUser({
                ...props.user,
                country_code: countryCode,
                phone_number: mobileNumber.trim(),
                is_two_factor_enable: 1,
              });
              set2FAStep(TwoFAType.Enabled);
              setLoading(false);
            })
            .catch((error) => {
              setLoading(false);
              if (errorResponseHandler(error, enqueueSnackbar)) {
                enqueueSnackbar(error.response.data.message, {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                });
              }
            });
        } else {
          setTwoFAError({ type: "matchLengthNumber" });
        }
      }
    } else if (twoFactorAuthentication === "authenticator") {
      if (twoFAStep === TwoFAType.SelectType) {
        setLoading(true);
        Service.post("twoFactor/generateAuthenticator", {
          type: "by_qrcode",
        })
          .then((response: any) => {
            setLoading(false);
            setManualCode(false);
            set2FAStep(TwoFAType.QRCode);
            setQrCodeSrc(response.data.url);
            setAuthenticatorKey(response.data.key);
          })
          .catch((error) => {
            setLoading(false);
            if (errorResponseHandler(error, enqueueSnackbar)) {
              enqueueSnackbar(error.response.data.message, {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              });
            }
          });
      } else if (twoFAStep === TwoFAType.QRCode) {
        set2FAStep(TwoFAType.EnterOTP);
      } else if (twoFAStep === TwoFAType.EnterOTP) {
        if (isValidOTP()) {
          setLoading(true);
          Service.post("twoFactor/verifyAuthenticator/enable", {
            token: otp.trim(),
          })
            .then((response: any) => {
              setLoading(false);
              if (response.data.verify) {
                props.setLoggedinUser({
                  ...props.user,
                  is_two_factor_enable: 2,
                });
                set2FAStep(TwoFAType.Enabled);
              } else {
                enqueueSnackbar(response.data.message, {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                });
              }
            })
            .catch((error) => {
              setLoading(false);
              if (errorResponseHandler(error, enqueueSnackbar)) {
                enqueueSnackbar(error.response.data.message, {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                });
              }
            });
        }
      }
    }
  };

  const formatPhoneNumber = (phoneNumber: number) => {
    const cleanNum = phoneNumber.toString().replace(/\D/g, "");
    const match = cleanNum.match(/^(\d{3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      return (
        "(" + match[1] + ") " + (match[2] ? match[2] + "-" : "") + match[3]
      );
    }
    return cleanNum;
  };

  const twoFAType = (
    <DialogContent>
      <Typography>Select the 2-Factor Authentication method.</Typography>
      <Box component="form" mt={3}>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="twoFactorAuthentication"
            defaultValue="textMessage"
            name="radio-buttons-group"
            onChange={(e) => setTwoFactorAuthentication(e.target.value)}
          >
            <FormControlLabel
              value="textMessage"
              control={<Radio />}
              label={
                <Typography>
                  Text Message
                  <br />
                  <Typography variant="caption">
                    We will send you a text message with a code to verify that
                    it’s you.
                  </Typography>
                </Typography>
              }
            />

            <Box mt={3} />

            <FormControlLabel
              value="authenticator"
              control={<Radio />}
              label={
                <Typography>
                  Authenticator
                  <br />
                  <Typography variant="caption">
                    Use an authenticator app such as Authy or Google
                    Authenticator
                  </Typography>
                </Typography>
              }
            />
          </RadioGroup>
        </FormControl>
      </Box>
    </DialogContent>
  );

  const twoFAEnterMobile = (
    <DialogContent>
      <Typography>
        Enter the phone number you wish to use for 2-Factor Authentication.
      </Typography>

      <Box component="form" mt={3}>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="phoneNumber" className="form-label">
            Phone Number
          </InputLabel>
          <MobileInput
            inputProps={{
              maxLength: 10,
            }}
            placeholder="Phone Number"
            id="phoneNumber"
            onCountryChange={(e: any) => setCountryCode(e.target.value)}
            onChange={(e: any) => setMobileNumber(e.target.value)}
            fullWidth
          />
          <ErrorMessage errors={twoFAError} label="10" />
        </FormControl>
      </Box>
    </DialogContent>
  );

  const twoFAVerify = (
    <DialogContent>
      <Typography>
        We just sent you a 6-digit code. Enter it below to confirm.
      </Typography>

      <Box component="form" mt={3}>
        <FormControl>
          <InputLabel shrink htmlFor="digitCode" className="form-label">
            6-digit Code
          </InputLabel>
          <PrimaryInput
            id="digitCode"
            inputProps={{
              maxLength: 6,
            }}
            onChange={(e: any) => setOTP(e.target.value)}
          />
          <ErrorMessage errors={twoFAError} label="6" maxLength="6" />
        </FormControl>
      </Box>
    </DialogContent>
  );

  const twoFAEnabled = (
    <DialogContent>
      <Typography>
        Success! 🎉 2-Factor Authentication is now enabled.
      </Typography>
    </DialogContent>
  );

  const qrCode = (
    <DialogContent>
      <Typography>
        Scan the QR code below with your authenticator app or enter the code
        manually.
      </Typography>

      <Box mt={3} alignItems="center" display="flex" flexDirection="column">
        <img alt="qr code" src={qrCodeSrc} style={{ maxWidth: "200px" }} />
        <Typography
          variant="h5"
          className={manualCode ? "" : "pointer"}
          align="center"
          onClick={(e) => setManualCode(true)}
        >
          {manualCode ? authenticatorKey : "Enter code manually"}
        </Typography>
      </Box>
    </DialogContent>
  );

  const twoWayAuthentication = (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open === "enablePop"}
      onClose={handleClose}
    >
      <DialogTitle>Enable 2-Factor Authentication</DialogTitle>
      {twoFAStep === TwoFAType.SelectType && twoFAType}
      {twoFAStep === TwoFAType.EnterMobile && twoFAEnterMobile}
      {twoFAStep === TwoFAType.EnterOTP && twoFAVerify}
      {twoFAStep === TwoFAType.Enabled && twoFAEnabled}
      {twoFAStep === TwoFAType.QRCode && qrCode}
      <Box p={2} pr={2}>
        <DialogActions>
          <Box className="btn-together">
            {twoFAStep !== TwoFAType.Enabled && (
              <PrimaryButton
                className="smFullWidth"
                variant="outlined"
                onClick={handleClose}
              >
                Close
              </PrimaryButton>
            )}
            {twoFAStep !== TwoFAType.Enabled && (
              <>
                <Box pl={1} />
                <PrimaryButton
                  className="smFullWidth"
                  onClick={next2FA}
                  loading={loading}
                >
                  Next
                </PrimaryButton>
              </>
            )}
            {twoFAStep === TwoFAType.Enabled && (
              <>
                <Box pl={1} />

                <PrimaryButton className="smFullWidth" onClick={handleClose}>
                  Done
                </PrimaryButton>
              </>
            )}
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );

  const disableTwoWayAuthentication = (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open === "disablePop"}
      onClose={handleClose}
    >
      <DialogTitle>Disable 2-Factor Authentication</DialogTitle>
      {twoFAStep !== TwoFAType.Enabled ? (
        <DialogContent>
          <Typography>
            Please enter a valid 6-digit authentication code to disable 2-Factor
            Authentication.
          </Typography>

          <Box component="form" mt={3}>
            <FormControl>
              <InputLabel shrink htmlFor="digitCode" className="form-label">
                6-digit Code
              </InputLabel>
              <PrimaryInput
                id="digitCode"
                inputProps={{
                  maxLength: 6,
                }}
                onChange={(e: any) => setOTP(e.target.value)}
              />
              <ErrorMessage errors={twoFAError} label="6" maxLength="6" />
            </FormControl>
          </Box>
        </DialogContent>
      ) : (
        <DialogContent>
          <Typography>2-Factor Authentication has been disabled.</Typography>
        </DialogContent>
      )}
      <Box mb={2} pr={2} mt={2}>
        <DialogActions>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item xs={12}>
              <Box className="btn-together">
                {twoFAStep !== TwoFAType.Enabled && (
                  <PrimaryButton
                    className="smFullWidth"
                    variant="outlined"
                    onClick={handleClose}
                  >
                    Close
                  </PrimaryButton>
                )}
                {twoFAStep !== TwoFAType.Enabled && (
                  <Box ml={1}>
                    <PrimaryButton
                      className="smFullWidth"
                      onClick={disable2FA}
                      loading={loading}
                    >
                      Disable 2-Factor Authentication
                    </PrimaryButton>
                  </Box>
                )}
                {twoFAStep === TwoFAType.Enabled && (
                  <Box ml={1}>
                    <PrimaryButton
                      className="smFullWidth"
                      onClick={handleClose}
                    >
                      Done
                    </PrimaryButton>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </DialogActions>
      </Box>
    </Dialog>
  );

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Security
      </Typography>
      <Box mt={5} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" gutterBottom>
            Password
          </Typography>
          <Box mt={2} />

          <PrimaryButton
            className="smFullWidth"
            onClick={() => props.setPopup("change_password")}
          >
            Change Password
          </PrimaryButton>
        </Grid>
      </Grid>
      <Box mt={5} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" gutterBottom>
            2-Factor Authentication
          </Typography>
          <Box mt={2} />

          <Typography variant="h5" gutterBottom>
            2-Factor Authentication is
            <b>
              {(props.user.is_two_factor_enable || 1) === 1
                ? " disabled"
                : " enabled"}
              .
            </b>
          </Typography>

          {props.user.is_two_factor_enable === 1 && (
            <Typography gutterBottom>
              {formatPhoneNumber(props.user.phone_number)}&nbsp;
              <span className="secondry-green">Verified</span>
            </Typography>
          )}
          <Box mt={3} />
          {props.user.is_two_factor_enable ? (
            <PrimaryButton
              className="smFullWidth"
              onClick={disable2FADisable}
              loading={loading}
            >
              Disable 2-Factor Authentication
            </PrimaryButton>
          ) : (
            <PrimaryButton className="smFullWidth" onClick={handleClickOpen}>
              Enable 2-Factor Authentication
            </PrimaryButton>
          )}
        </Grid>
      </Grid>
      {twoWayAuthentication}
      {disableTwoWayAuthentication}
      <ChangePasswordDialog />
    </>
  );
}

SecurityPage.propTypes = {
  window: PropTypes.func,
};

const mapStateToProps = (state: any) => {
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLoggedinUser: (user: any) =>
      dispatch({ type: "SET_LOGGIN_USER", user: user }),
    setPopup: (name: string) => dispatch({ type: "SET_POPUP", name: name }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SecurityPage);
