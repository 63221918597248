import React, { useRef } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import PrimaryButton from "../Component/PrimaryButton";
import UnauthenticateContainer from "./Layout/UnauthenticateContainer";
import PasswordInput from "../Component/PasswordInput";
import ErrorMessage from "../Component/ErrorMessage";
import { Service, errorResponseHandler } from "../Services/Service";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(0, 0, 2),
  },
  formControl: {
    width: "100%",
    margin: theme.spacing(0, 0, 2),
  },
}));

export default function ResetPassword(props: any) {
  const classes = useStyles();
  const history = useHistory();
  let token = props.match.params.token;
  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const password = useRef({});
  password.current = watch("password", "");
  const [loading, setLoading] = React.useState("none");

  const onSubmit = (data: any) => {
    setLoading("form_submit");
    Service.post("reset/password", {
      token: token,
      password: data.password,
    })
      .then((response) => {
        enqueueSnackbar(response.data.message, {
          key: new Date().getTime() + Math.random(),
          variant: "success",
        });
        history.push("/");
      })
      .catch((error) => {
        setLoading("");
        if (errorResponseHandler(error, enqueueSnackbar)) {
          enqueueSnackbar(error.response.data.message, {
            key: new Date().getTime() + Math.random(),
            variant: "error",
          });
        }
      });
  };

  return (
    <UnauthenticateContainer>
      <Box p={2} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h6" align="left">
            <b>Reset your password</b>
          </Typography>
          <Box p={1} />
          <Typography variant="h6" color="textSecondary">
            Choose a new password.
          </Typography>
          <Box p={2} />

          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="password" className="form-label">
                Password
              </InputLabel>
              <Controller
                name="password"
                control={control}
                rules={{
                  maxLength: 100,
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <PasswordInput
                    placeholder="Password"
                    id="password"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <ErrorMessage errors={errors.password} />
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel
                shrink
                htmlFor="Confirm password"
                className="form-label"
              >
                Password Confirmation
              </InputLabel>
              <Controller
                name="confirm_password"
                control={control}
                rules={{
                  maxLength: 100,
                  required: true,
                  validate: {
                    match: (value: string) => value === password.current,
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <PasswordInput
                    placeholder="Password Confirmation"
                    id="password_confirmation"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <ErrorMessage errors={errors.confirm_password} label="password" />
            </FormControl>

            <PrimaryButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              loading={loading === "form_submit"}
            >
              Reset
            </PrimaryButton>
          </form>
        </div>
      </Container>
    </UnauthenticateContainer>
  );
}
