import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Typography,
  makeStyles,
  Grid,
  IconButton,
  CardHeader,
  Avatar,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { Service, errorResponseHandler } from "../Services/Service";
import {
  DangerButton,
  PrimaryInput,
  ErrorMessage,
  PrimaryButton,
  UploadProfilePicture,
} from "../Component/index";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formControl: {
    width: "100%",
    margin: theme.spacing(0, 0, 2),
  },
  socialBtn: {
    borderRadius: "10px",
    fontSize: "16px",
    textTransform: "none",
    "& img": {
      marginRight: "10px",
    },
  },
  profileRoot: {
    padding: "8px",
  },
}));

function ProfilePage(props: any) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const removeProfileImage = () => {
    props.setConfirmationPopup({
      ...confirmationDialogConfig,
      loader: true,
    });
    Service.post("profile/changeImage", {
      profile_image: "",
      type: "delete",
    })
      .then((response) => {
        setLoading(false);
        props.setConfirmationPopup({
          ...confirmationDialogConfig,
          name: "",
        });
        props.setUser({
          ...props.user,
          profile_image: "",
        });
        enqueueSnackbar(response.data.message, {
          key: new Date().getTime() + Math.random(),
          variant: "success",
        });
      })
      .catch((error) => {
        setLoading(false);
        props.setConfirmationPopup({
          ...confirmationDialogConfig,
          loader: false,
        });
        if (errorResponseHandler(error, enqueueSnackbar)) {
          enqueueSnackbar(error.response.data.message, {
            key: new Date().getTime() + Math.random(),
            variant: "error",
          });
        }
      });
  };

  const confirmationDialogConfig = {
    name: "GlobalConfirmationDialog",
    title: "Remove Profile Photo",
    description: "Are you sure you want to remove your profile photo?",
    onConfirmation: removeProfileImage,
    loading: false,
  };

  const onSubmit = (data: any) => {
    setLoading(true);
    const profileData = {
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
    };

    Service.post("profile/edit", profileData)
      .then((response) => {
        setLoading(false);
        props.setUser({
          ...props.user,
          ...profileData,
          email_verified_at:
            data.email.toLowerCase() === props.user.email.toLowerCase()
              ? props.user.email_verified_at
              : null,
        });
        enqueueSnackbar(response.data.message, {
          key: new Date().getTime() + Math.random(),
          variant: "success",
        });
      })
      .catch((error) => {
        setLoading(false);
        if (errorResponseHandler(error, enqueueSnackbar)) {
          enqueueSnackbar(error.response.data.message, {
            key: new Date().getTime() + Math.random(),
            variant: "error",
          });
        }
      });
  };

  const getAvatarName = () => {
    return (
      props.user.first_name.charAt(0) + props.user.last_name.charAt(0)
    ).toUpperCase();
  };

  const resendVerificationEmail = (event: any) => {
    enqueueSnackbar(
      "We have sent verification email to your registered email",
      {
        key: new Date().getTime() + Math.random(),
        variant: "success",
      }
    );
    Service.post("profile/resend-email-verification", {})
      .then((response) => {})
      .catch((error) => {});
  };

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Profile
      </Typography>
      <Box mt={3} />
      <form
        className={classes.form}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}
          xl={4}
          sm={12}
          md={8}
          xs={12}
        >
          <Grid item xs={12}>
            <Typography variant="h5">Photo</Typography>
          </Grid>
          <Grid container xs={12}>
            <Box mb={2}>
              <CardHeader
                classes={{
                  root: classes.profileRoot,
                }}
                avatar={
                  <>
                    <IconButton
                      edge="end"
                      aria-label="account of current user"
                      aria-controls={"menuId"}
                      aria-haspopup="true"
                      color="inherit"
                      disableRipple={true}
                      style={{ padding: "0px", marginRight: "10px" }}
                    >
                      <Avatar
                        alt="Profile"
                        src={props.user.profile_image ?? ""}
                        style={{ width: "140px", height: "140px" }}
                      >
                        {getAvatarName()}
                      </Avatar>
                    </IconButton>
                  </>
                }
                title={
                  <Grid>
                    {!props.user.profile_image && (
                      <Grid
                        onClick={() => props.setPopup("change_photo")}
                        className="pointer underline"
                      >
                        Add Photo
                        <Box mb={2} />
                      </Grid>
                    )}

                    <Grid
                      onClick={() => props.setPopup("change_photo")}
                      className="pointer underline"
                    >
                      Change Photo
                    </Grid>
                    {props.user.profile_image && (
                      <>
                        <Box mb={2} />
                        <Grid
                          onClick={() =>
                            props.setConfirmationPopup(confirmationDialogConfig)
                          }
                          className="pointer underline"
                        >
                          Remove Photo
                        </Grid>
                      </>
                    )}
                  </Grid>
                }
              />
            </Box>
          </Grid>
          <Grid item xl={6} sm={6} xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="first_name" className="form-label">
                First Name
              </InputLabel>
              <PrimaryInput
                placeholder="First Name"
                defaultValue={props.user.first_name}
                id="first_name"
                {...register("first_name", {
                  required: true,
                })}
              />
              <ErrorMessage errors={errors.first_name} />
            </FormControl>
          </Grid>
          <Grid item xl={6} sm={6} xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="last_name" className="form-label">
                First Name
              </InputLabel>
              <PrimaryInput
                placeholder="Last Name"
                defaultValue={props.user.last_name}
                id="last_name"
                {...register("last_name", {
                  required: true,
                })}
              />
              <ErrorMessage errors={errors.last_name} />
            </FormControl>
          </Grid>
          {/* <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="phone_number" className="form-label">
                Phone Number
              </InputLabel>
              <MobileInput
                placeholder="Phone Number"
                defaultValue={props.user.phone_number}
                defaultCountryCode={props.user.country_code}
                id="phone_number"
                onCountryChange={(e: any) => setCountryCode(e.target.value)}
                onChange={(e: any) => setMobileNumber(e.target.value)}
              />
              <ErrorMessage errors={errors.phone_number} />
            </FormControl>
          </Grid> */}
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="email" className="form-label">
                Email
              </InputLabel>
              <PrimaryInput
                placeholder="email"
                defaultValue={props.user.email}
                id="email"
                {...register("email", {
                  required: true,
                })}
              />
              {props.user.email_verified_at === null && (
                <Typography>
                  Please verify your email.&nbsp;
                  <span onClick={resendVerificationEmail} className="make-link">
                    Resend verification email
                  </span>
                </Typography>
              )}
              <ErrorMessage errors={errors.email} />
            </FormControl>
          </Grid>
          <Grid item xs={12} direction="row-reverse">
            <PrimaryButton
              type="submit"
              variant="contained"
              disabled={loading}
              loading={loading}
              className="smFullWidth"
            >
              Save changes
            </PrimaryButton>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h3">Delete Account</Typography>
          </Grid>
          <Grid item xs={12}>
            <DangerButton
              onClick={() => history.push("/delete-account")}
              className="smFullWidth"
            >
              Delete my account
            </DangerButton>
          </Grid>
        </Grid>
      </form>
      <UploadProfilePicture />
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    loader: state.loader,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUser: (user: any) => dispatch({ type: "SET_LOGGIN_USER", user: user }),
    setPopup: (dialog: string) => dispatch({ type: "SET_POPUP", name: dialog }),
    setConfirmationPopup: (dialogObject: any) =>
      dispatch({ type: "SET_DIALOG_OBJECT", dialogObject: dialogObject }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
