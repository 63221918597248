
function validEmail(email: string) {
    //eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);  
}

function validExpiryDate(value: string) {
    //eslint-disable-next-line
    const re = /(0[1-9]|1[0-2])\/(\d{4}[^0-9])/g;
    return re.test(value);  
}
  
export {
    validEmail,
    validExpiryDate
}