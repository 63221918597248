import * as React from "react";
import PaginateComponent from "../Component/PaginateComponent";
import { Chip } from "@material-ui/core";
import { formateDate } from "../Helper/Common";

function StoredPage(props: any) {
  const headCells = [
   
    {
      id: "price",
      numeric: false,
      disablePadding: false,
      align: "center",
      label: "Price",
      beautify: (row: any) => {
        return row.price;
      },
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      align: "center",
      label: "Status",
      beautify: (row: any) => {
        if (row.status === "active") {
          return <Chip label="Active" size="small" color="primary" />;
        } else if (row.status === "inactive") {
          return <Chip label="Inactive" size="small" color="secondary" />;
        }
        return <Chip label="Payment failed" size="small" color="secondary" />;
      },
    },
    {
      id: "created_at",
      numeric: false,
      disablePadding: false,
      align: "right",
      label: "Created At",
      beautify: (row: any) => {
        return formateDate(new Date(row.created_at));
      },
    },
  ];

  return (
    <PaginateComponent
      apiPrefix="transactions"
      component="Transaction"
      headCells={headCells}
      title={"Transaction"}
    />
  );
}

export default StoredPage;
