import * as React from "react";
import { connect } from "react-redux";
import { Fab, Box } from "@material-ui/core";
import PaginateComponent from "../Component/PaginateComponent";
import { CreateWhitelist } from "../Popup";

function WhitelistPage(props: any) {
  const headCells = [
    {
      id: "whitelist_id",
      numeric: false,
      disablePadding: true,
      align: "left",
      label: "ID",
      beautify: (row: any) => {
        return row.id;
      },
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      align: "left",
      label: "Email",
      beautify: (row: any) => {
        return row.email;
      },
    },
  ];

  return (
    <>
      <Box className="floating-button">
        <Fab
          color="primary"
          aria-label="add"
          onClick={() => props.setPopup("CREATE_WHITELIST")}
        >
          +
        </Fab>
      </Box>
      <PaginateComponent
        apiPrefix="whitelist"
        component="whitelist"
        headCells={headCells}
        title={"Whitelist"}
      />
      <CreateWhitelist />
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPopup: (name: string) => dispatch({ type: "SET_POPUP", name: name }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WhitelistPage);
