import React from "react";
import { connect } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Header from "./Header";
import FullHeader from "./FullHeader";
import Sidebar from "./Sidebar";
import RightSidebarDetail from "../Layout/RightSidebarDetail";
import themeLight from "./theme";
import ChangePasswordDialog from "../../Component/ChangePasswordDialog";
import HeaderHeight from "../Layout/HeaderHeight";
import { getMe } from "../../actions/Account";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "calc(100vh - 64px)",
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(2, 3, 3, 3),
    [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2, 2, 2, 2),
    }
  },
}));

function AuthenticateContainer(props: any) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  React.useEffect(() => {
    props.getUser();
  }, []);

  return (
    <MuiThemeProvider theme={themeLight}>
      <HeaderHeight />
      <div className={classes.root}>
        <CssBaseline />
        {props.sidebar !== "none" ? (
          <Header
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
            goBack={props.goBack}
            header={props.header}
          />
        ) : (
          <FullHeader />
        )}
        {props.sidebar !== "none" && (
          <Sidebar
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
            type={props.sidebar ? props.sidebar : "profile"}
          />
        )}
        <main className={classes.content}>
          {props.children}
          <ChangePasswordDialog />
        </main>
        <RightSidebarDetail />
      </div>
    </MuiThemeProvider>
  );
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getUser: () => dispatch(getMe()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticateContainer);
