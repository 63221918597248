import React from "react";
import {
  Box,
  List,
  Drawer,
  Hidden,
  ListItem,
  ListItemText,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import * as Icon from "../../Icon/index";
import { Link, useLocation } from "react-router-dom";
import HeaderHeight from "../Layout/HeaderHeight";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  toolbarContainer: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    backgroundColor: "#12182B",
    position: "fixed",
    width:"100%",
    zIndex: 111
  },
  drawerPaper: {
    width: drawerWidth,
    borderRight: "none",
    display: "flex",
    justifyContent: "space-between",
  },
  mobileRoot: {
    margin: "0px",
  },

  menuList: {
    "& > a": {
      textDecoration: "none",
      color: "#153340",
    },
  },
  storageContent: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  logo: {
    textDecoration: "none",
    display: "flex",
  },
}));

function MenuItemIcon(props: any) {
  return (
    <div style={{ width: "35px", display: "flex", justifyContent: "center" }}>
      {props.children}
    </div>
  );
}

function Sidebar(props: any) {
  const { mobileOpen, handleDrawerToggle, type } = props;
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const container = undefined;
  const [pathname, setPathname] = React.useState("");
  const hidden = useMediaQuery((theme: any) => theme.breakpoints.down("xs"));

  React.useEffect(() => {
    const url = new URL(document.location.href);
    setPathname(url.pathname);
  }, [location]);

  const drawer = (
    <div>
      <Box m={2} mr={5} mt={2}>
      <HeaderHeight />
        <List className={classes.menuList} id="navigation">
          <Link to={"/"}>
            <ListItem
              button
              key={"all-files"}
              className="borderRadious"
              onClick={handleDrawerToggle}
              selected={pathname.search("folder") !== -1 || pathname === "/"}
            >
              <MenuItemIcon className="center" style={{ width: "35px" }}>
                <Icon.Folder />
              </MenuItemIcon>
              &nbsp;&nbsp;
              <ListItemText primary={"Users"} />
            </ListItem>
          </Link>
          {/* <Link to={"/transaction"}>
            <ListItem
              button
              key={"Available"}
              className="borderRadious"
              onClick={handleDrawerToggle}
              selected={pathname.search("transaction") !== -1}
            >
              <MenuItemIcon className="center">
                <Icon.Available />
              </MenuItemIcon>
              &nbsp;&nbsp;
              <ListItemText primary={"Transaction"} />
            </ListItem>
          </Link> */}

          <Link to={"/whitelist"}>
            <ListItem
              button
              key={"Available"}
              className="borderRadious"
              onClick={handleDrawerToggle}
              selected={pathname.search("whitelist") !== -1}
            >
              <MenuItemIcon className="center">
                <Icon.Available />
              </MenuItemIcon>
              &nbsp;&nbsp;
              <ListItemText primary={"Whitelist"} />
            </ListItem>
          </Link>
          <Link to={"/feedbacks"}>
            <ListItem
              button
              key={"Feedbacks"}
              className="borderRadious"
              onClick={handleDrawerToggle}
              selected={pathname.search("feedbacks") !== -1}
            >
              <MenuItemIcon className="center">
                <Icon.Available />
              </MenuItemIcon>
              &nbsp;&nbsp;
              <ListItemText primary={"Feedbacks"} />
            </ListItem>
          </Link>

          <Link to={"/reasons"}>
            <ListItem
              button
              key={"Feedbacks"}
              className="borderRadious"
              onClick={handleDrawerToggle}
              selected={pathname.search("reasons") !== -1}
            >
              <MenuItemIcon className="center">
                <Icon.Available />
              </MenuItemIcon>
              &nbsp;&nbsp;
              <ListItemText primary={"Reasons"} />
            </ListItem>
          </Link>
        </List>
      </Box>
    </div>
  );

  return (
    <nav className={classes.drawer + " sidebar"} aria-label="mailbox folders">
      {hidden ? (
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
            className="sidebar-drawer"
          >
            {drawer}
            
          </Drawer>
        </Hidden>
      ) : (
        <Hidden xsDown implementation="css">
          <Drawer
            className="sidebar-drawer"
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      )}
    </nav>
  );
}

export default Sidebar;
