import videoIcon from "../images/video.svg";
import imageIcon from "../images/image.svg";
import fileIcon from "../images/file.svg";
import videoWhiteIcon from "../images/video-white.svg";
import imageWhiteIcon from "../images/image-white.svg";
import fileWhiteIcon from "../images/file-white.svg";

const bytesToSize = (bytes: number, withPrefix = true) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
  if (isNaN(bytes)) return "0 Byte";
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());
  if (withPrefix) {
    return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[i];
  }
  return Math.round(bytes / Math.pow(1024, i));
};

const bytesToUnit = (bytes: number, unit: string, absolute = true) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
  if (isNaN(bytes)) return 0;
  if (bytes === 0) return 0;
  const unitIndex = sizes.indexOf(unit);
  if (absolute) {
    return parseInt((bytes / Math.pow(1024, unitIndex)).toString());
  }
  return (bytes / Math.pow(1024, unitIndex)).toFixed(1);
};

const formateDate = (date: Date) => {
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
};

const formateAmount = (amount: number) => {
  return (amount || 0).toFixed(2);
};

const getFileExt = (type: string) => {
  if (type && type.split(".").length > 1) {
    return type.split(".").pop();
  }
  return "unknown";
};

const getFileType = (type: string) => {
  if (type && type.split("/").length > 1) {
    return type.split("/")[0];
  }
  return "unknown";
};

const getFileTypeIcon = (type: string) => {
  let file = fileIcon;
  switch (getFileType(type)) {
    case "image":
      file = imageIcon;
      break;
    case "video":
      file = videoIcon;
      break;
  }
  return file;
};

const getSecondryFileIcon = (type: string) => {
  let file = fileWhiteIcon;
  switch (getFileType(type)) {
    case "image":
      file = imageWhiteIcon;
      break;
    case "video":
      file = videoWhiteIcon;
      break;
  }
  return file;
};

export {
  bytesToSize,
  formateDate,
  getFileType,
  getFileTypeIcon,
  getFileExt,
  getSecondryFileIcon,
  bytesToUnit,
  formateAmount
};
