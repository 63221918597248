import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import * as Icon from "../Icon/index";

const PlanCard = (props: any) => {
  return (
    <Box
      onClick={props.onClick}
      className={`plan-box ${
        props.selectedPlan === props.index ? "active" : ""
      } variant-${props.variant}`}
      mt={2}
      p={2}
      display="flex"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        width="130px"
        className="plan-amount"
      >
        <Typography variant="h2" className="f-32">
          {props.size}
        </Typography>

        <Typography variant="h6" className="f-12">
          ${props.price.toFixed(2)}/month
        </Typography>
        {props.planDuration === 1 && (
          <Typography variant="h6" className="f-12">
            Billed annually
          </Typography>
        )}
      </Box>
      <Box>
        <List dense={true} disablePadding={true}>
          {props.planDetail.map((item: any) => (
            <ListItem disableGutters>
              <ListItemIcon>
                <Icon.Check className="green-icon feature" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2" className="f-12">
                    {item}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default PlanCard;
