import { Service } from "../Services/Service";

export const getCountry = () => {
  return (dispatch) => {
    Service.get("country")
      .then((response) => {
        dispatch(setCountry(response.data.data));
      })
      .catch((error) => {});
  };
};

export const getState = (country_id) => {
  return (dispatch) => {
    Service.get(`state?country_id=${country_id}`)
      .then((response) => {
        dispatch(setState(country_id, response.data.data));
      })
      .catch((error) => {});
  };
};

export const setCountry = (countries) => {
  return {
    type: "SET_COUNTRY",
    countries,
  };
};

export const setState = (country_id, states) => {
  return {
    type: "SET_STATE",
    country_id,
    states,
  };
};
