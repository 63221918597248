
const generateThumbnail = (file: any, boundBox: any) => {
    // if (!boundBox || boundBox.length != 2){
    //   throw "You need to give the boundBox"
    // }
    let scaleRatio = Math.min(...boundBox) / Math.max(file.width, file.height);
    var reader = new FileReader();
    var canvas = document.createElement("canvas");
    const ctx: any = canvas.getContext("2d");

    return new Promise((resolve, reject) => {
      reader.onload = function (event: any) {
        let img: any = new Image();
        img.onload = function () {
          scaleRatio = Math.min(...boundBox) / Math.max(img.width, img.height);
          let w = img.width * scaleRatio;
          let h = img.height * scaleRatio;
          canvas.width = w;
          canvas.height = h;
          ctx.drawImage(img, 0, 0, w, h);

          canvas.toBlob(
            function (blob: any) {
              resolve(blob);
            },
            "image/jpeg",
            0.8
          );
          return;
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
};

const generateVideoThumbnail = (file: File) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const video = document.createElement("video");

      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      video.onloadeddata = () => {
        let ctx: any = canvas.getContext("2d");

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        
        video.pause();
        // return resolve(canvas.toDataURL("image/png"));
        canvas.toBlob(
            function (blob: any) {
              resolve(blob);
            },
            "image/png",
            0.7
          );
      };
    });
};

export {
    generateThumbnail,
    generateVideoThumbnail
}