import { connect } from "react-redux";
import { Box, Typography, Grid, Link } from "@material-ui/core";
import AuthenticateContainer from "./Layout/AuthenticateContainer";

function PreLaunchPage() {
  //   const classes = useStyles();

  return (
    <AuthenticateContainer sidebar="none">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ height: "calc(100vh - 120px)" }}
      >
        <Grid item xs={10} sm={6} md={5} xl={4} lg={4}>
          <Typography gutterBottom className="f-32">
            <b>Your account has been created!</b>
          </Typography>
          <Box mt={3}>
            <Typography gutterBottom>
              Thanks for creating an account. We’re still preparing for launch
              and will send you an email when your account is ready! 🚀
            </Typography>
          </Box>
          <Box mt={3}>
            <Typography gutterBottom>
              In the meantime,{" "}
              <Link
                href="https://twitter.com/alpiistorage"
                className="underline primary"
                target="_blank"
              >
                follow us on Twitter
              </Link>{" "}
              for updates.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </AuthenticateContainer>
  );
}

const mapStateToProps = (state: any) => {
  return {
    loader: state.loader,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUser: (user: any) => dispatch({ type: "SET_LOGGIN_USER", user: user }),
    setPopup: (dialog: string) => dispatch({ type: "SET_POPUP", name: dialog }),
    setConfirmationPopup: (dialogObject: any) =>
      dispatch({ type: "SET_DIALOG_OBJECT", dialogObject: dialogObject }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreLaunchPage);
