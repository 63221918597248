import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import PrimaryButton from "../Component/PrimaryButton";
import PrimaryInput from "../Component/PrimaryInput";
import UnauthenticateContainer from "./Layout/UnauthenticateContainer";
import { useLocation, useHistory } from "react-router-dom";
import ErrorMessage from "../Component/ErrorMessage";
import { useForm } from "react-hook-form";
import { Service, errorResponseHandler } from "../Services/Service";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  or: {
    textAlign: "center",
    borderBottom: "2px solid rgb(245 239 239)",
    lineHeight: "0.1em",
    margin: "25px 0",
    width: "100%",
    "& > span": {
      background: "#fff",
      padding: "0 10px",
    },
  },
  formControl: {
    width: "100%",
    margin: theme.spacing(0, 0, 2),
  },
  socialBtn: {
    borderRadius: "10px",
    fontSize: "16px",
    textTransform: "none",
    "& img": {
      marginRight: "10px",
    },
  },
}));

function OtpVerification(props: any) {
  const classes = useStyles();
  const history = useHistory();
  const search = useLocation().search;
  const email = new URLSearchParams(search).get("email");
  const redirect = new URLSearchParams(search).get("redirect");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    setLoading(true);
    Service.post("twoFactor/login", {
      email: email,
      token: data.otp,
      type: "text",
    })
      .then((response) => {
        loginSuccess(response);
      })
      .catch((error) => {
        loginFail(error);
      });
  };

  const loginSuccess = (response: any) => {
    props.setLoggedinUser(response.data.data);
    enqueueSnackbar(response.data.message, {
      key: new Date().getTime() + Math.random(),
      variant: "success",
    });

    if (redirect) {
      history.push({
        pathname: redirect,
      });
    } else {
      history.push("/");
    }
  };

  const loginFail = (error: any) => {
    setLoading(false);
    if (errorResponseHandler(error, enqueueSnackbar)) {
      enqueueSnackbar(error.response.data.message, {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      });
    }
  };

  return (
    <UnauthenticateContainer>
      <Box p={2} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h4" align="left">
            <b>Sign in to your account</b>
          </Typography>
          <Box p={1} />
          <Typography variant="subtitle1" align="left">
            Enter the 6-digit code generated by your authenticator app.
          </Typography>
          <Box p={2} />

          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box mt={3} mb={3}>
              <FormControl>
                <InputLabel shrink htmlFor="otp" className="form-label">
                  6-digit Code
                </InputLabel>
                <PrimaryInput
                  id="otp"
                  inputProps={{
                    maxLength: 6,
                  }}
                  {...register("otp", {
                    required: true,
                    validate: {
                      number: (value: string) => !isNaN(parseInt(value.trim())),
                      matchLengthNumber: (value: string) =>
                        value.trim().length === 6,
                    },
                  })}
                />
                <ErrorMessage errors={errors.otp} label="6" />
              </FormControl>
            </Box>

            <PrimaryButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              loading={loading}
              className={classes.submit}
            >
              Sign In
            </PrimaryButton>
          </form>
        </div>
      </Container>
    </UnauthenticateContainer>
  );
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLoggedinUser: (user: any) =>
      dispatch({ type: "SET_LOGGIN_USER", user: user }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OtpVerification);
