import React, { useRef } from "react";
import {
  Box,
  Tab,
  Tabs,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  Typography,
  Link,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import {
  PrimaryButton,
  PrimaryInput,
  ErrorMessage,
  PlanCard,
} from "../Component";
import { Service, errorResponseHandler } from "../Services/Service";
import * as Icon from "../Icon/index";
import { PlanList } from "../Config/Config";
import { UpgradeModel } from "../Models/UpgradeModel";
import { getSubscriptionData } from "../actions/Account";
import { getCountry, getState } from "../actions/Utility";
import { PrimarySelect } from "../Component";

const UpgradeAccount = (props: any) => {
  const [addressState, setAddressState] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [step, setStep] = React.useState<string>(UpgradeModel.PLAN);
  const [planDuration, setPlanDuration] = React.useState(1);
  const [selectedPlan, setSelectedPlan] = React.useState(1);
  const [selectedItem, setSelectedItem] = React.useState({
    duration: 0,
    size: "",
    planDetail: ["5"],
    price: 0,
    downloadPrice: 0,
    free_download: 0,
    sizeInByte: 0,
  });
  const [isCardUpdated, setIsCardUpdated] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit,
    reset,
    watch,
    register,
    formState: { errors },
  } = useForm();
  const new_password = useRef({});
  new_password.current = watch("new_password", "");

  const handlePlanDurationChange = (event: any, newValue: number) => {
    setPlanDuration(newValue);
  };

  const getPaymentDetail = () => {
    if (props.dialog === "UpgradeAccount") {
      Service.get(`user/get-payment-method`)
        .then((response) => {
          if (response.data.data.hasOwnProperty("name")) {
            setIsCardUpdated(true);
            reset({
              credit_card_number: `XXXX-XXXX-XXXX-${response.data.data.last4}`,
              cvc: "XXX",
              expiry: "XX/XXXX",
              cardholder_name: response.data.data.name,
              address_line_1: response.data.data.address_line1,
              address_line_2: response.data.data.address_line2,
              city: response.data.data.address_city,
              state: response.data.data.address_state,
              zip_code: response.data.data.address_zip,
            });
          }
        })
        .catch((error) => {
          if (errorResponseHandler(error, enqueueSnackbar)) {
            enqueueSnackbar(error.response.data.message, {
              key: new Date().getTime() + Math.random(),
              variant: "error",
            });
          }
        });
    }
  };

  const handleClose = () => {
    props.setPopup("");
    setStep(UpgradeModel.PLAN);
    reset({
      name: "",
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      zip_code: "",
    });
  };

  const formatCardNumber = (value: string) => {
    const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
    const onlyNumbers = value.replace(/[^\d]/g, "");

    return onlyNumbers.replace(
      regex,
      (regex: any, $1: any, $2: any, $3: any, $4: any, $5: any) =>
        [$1, $2, $3, $4, $5].filter((group) => !!group).join(" ")
    );
  };

  const formatExpiryDate = (event: any) => {
    const code = event.keyCode;
    const allowedKeys = [8];
    if (allowedKeys.indexOf(code) !== -1) {
      return;
    }

    event.target.value = event.target.value
      .replace(
        /^([1-9]\/|[2-9])$/g,
        "0$1/" // 3 > 03/
      )
      .replace(
        /^(0[1-9]|1[0-2])$/g,
        "$1/" // 11 > 11/
      )
      .replace(
        /^([0-1])([3-9])$/g,
        "0$1/$2" // 13 > 01/3
      )
      .replace(
        /^(0?[1-9]|1[0-2])([0-9]{4})$/g,
        "$1/$2" // 141 > 01/41
      )
      .replace(
        /^([0]+)\/|[0]+$/g,
        "0" // 0/ > 0 and 00 > 0
      )
      .replace(
        /[^\d\/]|^[\/]*$/g,
        "" // To allow only digits and `/`
      )
      .replace(
        /\/\//g,
        "/" // Prevent entering more than 1 `/`
      );
  };

  const onSubmit = (data: any) => {
    let param: any = {
      plan_duration: planDuration,
      selected_plan: selectedPlan,
    };
    setLoading(true);
    if (!isCardUpdated) {
      const expiry = data.expiry.split("/");
      if (expiry.length < 2) return;
      param = {
        ...param,
        credit_card_number: data.credit_card_number.replace(/[^\d]/g, ""),
        exp_month: expiry[0],
        exp_year: expiry[1],
        cvc: data.cvc,
        cardholder_name: data.cardholder_name,
        address_line_1: data.address_line_1,
        address_line_2: data.address_line_2,
        city: data.city,
        state: data.state,
        country: data.country,
        zip_code: data.zip_code,
      };
    }

    let apiUrl = "user/update-payment-method";
    if (props.user && props.user.is_subscribe === 1) {
      apiUrl = "user/update-subscription";
    }

    Service.post(apiUrl, param)
      .then((response) => {
        setLoading(false);
        props.setUser({
          ...props.user,
          is_subscribe: 1,
        });
        setStep(UpgradeModel.ACCOUNT_UPGRADED);
        props.getSubscriptionData();
      })
      .catch((error) => {
        setLoading(false);
        if (errorResponseHandler(error, enqueueSnackbar)) {
          if (error.response.data.hasOwnProperty("status")) {
            if (error.response.data.status === "CAN_NOT_DOWNGRADE") {
              setStep(UpgradeModel.DOWNGRADE_OVER_SIZE);
              return;
            }
          }
          enqueueSnackbar(error.response.data.message, {
            key: new Date().getTime() + Math.random(),
            variant: "error",
          });
        }
      });
  };

  React.useEffect(() => {
    if (props.dialog === "UpgradeAccount") {
      getSelectedPlan().forEach((plan) => {
        setSelectedItem(plan);
      });
      getPaymentDetail();
      props.getSubscriptionData();
      if (props.countries.length === 0) {
        props.getCountry();
      }
    }
    //eslint-disable-next-line
  }, [props.dialog]);

  React.useEffect(() => {
    if (props.subscription.plan) {
      setPlanDuration(props.subscription.plan_duration);
      setSelectedPlan(props.subscription.plan);
    }
  }, [props.dialog, props.subscription]);

  const onCountryChange = (event: any) => {
    setAddressState("");
    setCountry(event.target.value);
    getState(event.target.value);
  };

  const getState = (country_name: string) => {
    let country = props.countries.find(
      (country: any) => country.name === country_name
    );
    if (country) {
      return props.getState(country.id);
    }
    return props.getState(231);
  };

  const paymentForm = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl>
              <InputLabel
                shrink
                htmlFor="credit_card_number"
                className="form-label"
                color="secondary"
              >
                Credit Card Number
              </InputLabel>

              <PrimaryInput
                fullWidth
                color="secondary"
                placeholder="Credit Card Number"
                id="credit_card_number"
                inputProps={{ maxLength: 19 }}
                {...register("credit_card_number", {
                  required: true,
                })}
                onKeyUp={(event) => {
                  event.currentTarget.value = formatCardNumber(
                    event.currentTarget.value
                  );
                }}
              />
              <ErrorMessage errors={errors.credit_card_number} />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <InputLabel
                shrink
                htmlFor="expiry"
                className="form-label"
                color="secondary"
              >
                Expiry
              </InputLabel>

              <PrimaryInput
                placeholder="MM/YYYY"
                color="secondary"
                id="expiry"
                inputProps={{ maxLength: 7 }}
                {...register("expiry", {
                  required: true,
                })}
                onKeyUp={formatExpiryDate}
              />
              <ErrorMessage errors={errors.expiry} />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl>
              <InputLabel
                shrink
                htmlFor="cvc"
                className="form-label"
                color="secondary"
              >
                CVC
              </InputLabel>

              <PrimaryInput
                placeholder="CVC"
                color="secondary"
                id="cvc"
                {...register("cvc", {
                  required: true,
                })}
                inputProps={{ maxLength: 4 }}
                onKeyUp={(event) => {
                  event.currentTarget.value = event.currentTarget.value.replace(
                    /[^\d]/g,
                    ""
                  );
                }}
              />
              <ErrorMessage errors={errors.cvc} />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <InputLabel
                shrink
                color="secondary"
                htmlFor="cardholder_name"
                className="form-label"
              >
                Cardholder Name
              </InputLabel>

              <PrimaryInput
                placeholder="Cardholder Name"
                color="secondary"
                id="cardholder_name"
                {...register("cardholder_name", {
                  required: true,
                })}
              />
              <ErrorMessage errors={errors.cardholder_name} />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <InputLabel
                shrink
                color="secondary"
                htmlFor="address_line_1"
                className="form-label"
              >
                Address Line 1
              </InputLabel>

              <PrimaryInput
                placeholder="Address Line 1"
                color="secondary"
                id="address_line_1"
                {...register("address_line_1", {
                  required: true,
                })}
              />
              <ErrorMessage errors={errors.address_line_1} />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <InputLabel
                shrink
                color="secondary"
                htmlFor="address_line_2"
                className="form-label"
              >
                Address Line 2
              </InputLabel>

              <PrimaryInput
                placeholder="Address Line 2"
                color="secondary"
                id="address_line_2"
                {...register("address_line_2")}
              />
              <ErrorMessage errors={errors.address_line_2} />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Box mb={1}>
              <InputLabel
                shrink
                htmlFor="country"
                className="form-label"
                color="secondary"
              >
                Country
              </InputLabel>
            </Box>

            <PrimarySelect
              fullWidth
              displayEmpty
              disableUnderline
              defaultValue="United States"
              color="secondary"
              {...register("country", {
                required: true,
              })}
              onChange={onCountryChange}
              value={country}
            >
              <MenuItem value="">Select country</MenuItem>
              {props.countries.map((country: any) => (
                <MenuItem value={country.name}>{country.name}</MenuItem>
              ))}
            </PrimarySelect>
          </Grid>
          <Grid item xs={6}>
            <Box mb={1}>
              <InputLabel
                shrink
                htmlFor="state"
                className="form-label"
                color="secondary"
              >
                State
              </InputLabel>
            </Box>
            <PrimarySelect
              fullWidth
              displayEmpty
              disableUnderline
              defaultValue={addressState}
              value={addressState}
              color="secondary"
              {...register("state", {
                required: true,
              })}
              onChange={(event: any) => {
                setAddressState(event.target.value);
              }}
            >
              <MenuItem value="">Select state</MenuItem>
              {props.stateList.map((state: any) => (
                <MenuItem value={state.name}>{state.name}</MenuItem>
              ))}
            </PrimarySelect>
            <ErrorMessage errors={errors.state} />
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <InputLabel
                shrink
                htmlFor="city"
                className="form-label"
                color="secondary"
              >
                City
              </InputLabel>

              <PrimaryInput
                placeholder="City"
                color="secondary"
                id="city"
                {...register("city")}
              />
              <ErrorMessage errors={errors.city} />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <InputLabel
                shrink
                color="secondary"
                htmlFor="zip_code"
                className="form-label"
              >
                Zip Code
              </InputLabel>

              <PrimaryInput
                placeholder="Zip Code"
                color="secondary"
                id="zip_code"
                {...register("zip_code")}
              />
              <ErrorMessage errors={errors.zip_code} />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box className="btn-together">
              <PrimaryButton
                variant="contained"
                type="submit"
                loading={loading}
                disable={loading}
                className="smFullWidth type-2"
              >
                Upgrade
              </PrimaryButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );

  const proceedToPayment = () => {
    console.log("sdf", selectedItem.sizeInByte, props.user.used_storage);
    console.log("sdf", props.user.used_storage);
    const canDowngrade = selectedItem.sizeInByte >= props.user.used_storage;
    if (!canDowngrade) {
      setStep(UpgradeModel.DOWNGRADE_OVER_SIZE);
      return;
    }
    if (isCardUpdated) {
      onSubmit({});
    } else {
      setStep(UpgradeModel.PAYMENT_FORM);
    }
  };

  const handlePlanSelect = (plan: any, index: number) => {
    setSelectedPlan(index + 1);
    setSelectedItem(plan);
  };

  const getSelectedPlan = () => {
    const plan = PlanList.filter((plan) => plan.duration === planDuration);
    if (plan.length === 0) {
      return [];
    }

    return [plan[selectedPlan - 1]];
  };

  const getSubTitle = () => {
    console.log("props.data", props.data);
    if (props.data.type === "LIMIT_EXCEEDED") {
      if (props.user.is_subscribe === 1) {
        return "You’ve reached the storage limit for your current plan. Upgrade your plan to continue uploading files.";
      }
      return "You’ve reached the storage limit for your free plan. Upgrade to a paid plan to continue uploading files.";
    }

    return `Choose a plan below to change your plan`;
  };

  const successPage = () => {
    return (
      <>
        <Typography variant="h6">
          {props.data.type === "CHANGE_PLAN"
            ? "Thanks for changing your plan! You are now on the following plan:"
            : "Thanks for upgrading! You have been upgraded to the following plan:"}
        </Typography>

        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={10}>
            {getSelectedPlan().map((plan, index) => (
              <PlanCard
                key={`plan-${selectedPlan}-` + index}
                index={index + 1}
                size={plan.size}
                planDetail={plan.planDetail}
                price={plan.price}
                planDuration={planDuration}
                selectedPlan={selectedPlan}
              />
            ))}
          </Grid>
          <Grid item xs={12}>
            <Box className="btn-together" mt={3}>
              <PrimaryButton
                variant="contained"
                className="smFullWidth type-2"
                onClick={handleClose}
              >
                Done
              </PrimaryButton>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  const downgradeOverSize = (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <Box
          justifyContent="center"
          display="flex"
          flexDirection="column"
          height="100%"
        >
          <Typography variant="h6">
            We could not downgrade your plan because your current storage
            exceeds the new plan allowance. Please remove data and try again.
          </Typography>
          <Box mb={3} mt={2}>
            <Typography variant="h6">
              Note: files count towards storage usage for a minimum of six
              months.
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" mb={5}>
            <Link
              href={`${process.env.REACT_APP_WEBSITE_URL}/faq#why-six-month-storage-minimum`}
              target="_blank"
            >
              <Typography variant="h5" className="color-white make-link">
                Learn more about the minimum storage period
              </Typography>
            </Link>
          </Box>
          <Box className="btn-together">
            <PrimaryButton
              variant="contained"
              onClick={handleClose}
              className="smFullWidth type-2"
            >
              Done
            </PrimaryButton>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <Dialog
      id="upgrade-account"
      scroll="body"
      fullWidth={true}
      open={props.dialog === "UpgradeAccount"}
      onClose={handleClose}
      className="dark-mode"
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            {step === UpgradeModel.DOWNGRADE_OVER_SIZE ? (
              <Typography
                color="error"
                variant="h3"
                className="sm-upcc"
                style={{ display: "flex" }}
              >
                <Icon.Info className="danger-icon" height="24" width="24" />
                &nbsp; &nbsp;Could Not Downgrade Plan
              </Typography>
            ) : (
              <Typography variant="h3">
                {step === UpgradeModel.PLAN
                  ? "Choose a Plan"
                  : step === UpgradeModel.PAYMENT_FORM
                  ? "Enter payment information"
                  : step === UpgradeModel.ACCOUNT_UPGRADED
                  ? props.data.type === "CHANGE_PLAN"
                    ? "Your plan has been changed! 🎉"
                    : "Your account has been upgraded! 🎉"
                  : "Choose a Plan"}
              </Typography>
            )}
          </Box>
          <Box>
            <IconButton onClick={handleClose}>
              <Icon.Cancel className="white-icon" />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent>
        {step === UpgradeModel.DOWNGRADE_OVER_SIZE && downgradeOverSize}
        {step === UpgradeModel.ACCOUNT_UPGRADED && successPage()}
        {step === UpgradeModel.PAYMENT_FORM && paymentForm}
        {step === UpgradeModel.PLAN && (
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Box
                justifyContent="center"
                display="flex"
                flexDirection="column"
                height="100%"
              >
                <Box mb={5}>
                  <Typography>{getSubTitle()}</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={10} xs={12}>
              <Box
                justifyContent="center"
                display="flex"
                flexDirection="column"
                height="100%"
              >
                <Tabs
                  id="plan_duration_tab"
                  value={planDuration}
                  onChange={handlePlanDurationChange}
                  centered
                >
                  <Tab label="Montly" />
                  <Tab label="Yearly" />
                </Tabs>
                {PlanList.filter((plan) => plan.duration === planDuration).map(
                  (plan, index) => (
                    <PlanCard
                      key={`plan-${selectedPlan}-` + index}
                      index={index + 1}
                      size={plan.size}
                      planDetail={plan.planDetail}
                      price={plan.price}
                      planDuration={planDuration}
                      selectedPlan={selectedPlan}
                      onClick={() => handlePlanSelect(plan, index)}
                    />
                  )
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mt={5} mb={5}>
                <Link href="https://alpii.com/pricing" target="_blank">
                  <Typography variant="h5" className="color-white make-link">
                    Learn more about pricing
                  </Typography>
                </Link>
              </Box>
              <Box className="btn-together">
                <PrimaryButton
                  loading={loading}
                  variant="contained"
                  onClick={() => proceedToPayment()}
                  className="smFullWidth type-2"
                >
                  Continue
                </PrimaryButton>
              </Box>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    dialog: state.dialog,
    data: state.dialogObject,
    countries: state.countries,
    stateList: state.states,
    subscription: state.subscription,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPopup: (name: string) =>
      dispatch({ type: "SET_DIALOG_OBJECT", dialogObject: { name: "" } }),
    setUser: (user: any) => dispatch({ type: "SET_LOGGIN_USER", user: user }),
    getSubscriptionData: () => dispatch(getSubscriptionData()),
    getCountry: () => dispatch(getCountry()),
    getState: (country_id: number) => dispatch(getState(country_id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeAccount);
