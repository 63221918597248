import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography, Slide } from "@material-ui/core";
import DangerButton from "../../Component/DangerButton";
import PrimaryButton from "../../Component/PrimaryButton";
import ConfirmationDialog from "../../Component/ConfirmationDialog";
import {
  bytesToSize,
  formateDate,
  getFileExt,
  getFileTypeIcon,
} from "../../Helper/Common";
import * as Icon from "../../Icon";
import {
  FileStatus,
  ProcessFileIcon,
  WhiteFileIcon,
} from "../../Models/FileStatus";
import { Service, token } from "../../Services/Service";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "350px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  container: {
    height: "calc(100vh - 65px)",
    position: "fixed",
    paddingLeft: "15px",
    paddingRight: "15px",
    right: "0",
    width: "350px",
    background: "#fff",
    borderLeft: "solid 1px #ccc",
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
  },
  previewContainer: {
    display: "flex",
    justifyContent: "center",
  },
  preview: {
    maxWidth: "100%",
    width: "100%",
    maxHeight: "35vh",
    objectFit: "contain",
    minHeight: "200px",
  },
  headerRoot: {
    width: "250px",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },
  },
  middleContainer: {
    overflow: "auto",
    maxHeight: "calc(100vh - 250px)",
  },
}));

function RightSidebarDetail(props: any) {
  const classes = useStyles();
  const location = useLocation();
  const [loading, setLoading] = React.useState("");
  const [dialogBox, setDialogBox] = React.useState("");
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (props.dialog === "FILE_DETAIL") {
      if (location.pathname !== "/") {
        props.closeSidebar();
      }
    }
  //eslint-disable-next-line
}, [location]);

  const deletedInDays = () => {
    const days = moment(props.fileDetail.deleted_at)
      .startOf("day")
      .diff(moment().subtract(30, "day").startOf("day"), "days");
    return days > 1 ? `${days} days` : "1 day";
  };

  const downloadFolder = () => {
    props.uploadFile({
      id: props.fileDetail.id,
      folderId: props.fileDetail.id,
      name: `Zipping ${props.fileDetail.name}`,
      percent: 0,
      type: "zip",
      status: "active",
    });

    Service.get(`/download/folder/${props.fileDetail.id}`)
      .then((response: any) => {
        props.removeUploadFile(props.fileDetail.id);
        props.updateRetrival(props.fileDetail.size);
        document.location.href = response.data.downloadurl;
      })
      .catch((error) => {
        props.removeUploadFile(props.fileDetail.id);
      });
  };

  const download = (value: any) => {
    if (props.fileDetail.type === "folder") {
      return downloadFolder();
    }
    props.updateRetrival(props.fileDetail.size);
    document.location.href = `${process.env.REACT_APP_API_URL}/glaceir/outPutJob/${props.fileDetail.id}?token=${token}`;
  };

  const confirmPermanentlyDelete = (e: any) => {
    setDialogBox("permanentlyDelete");
  };

  const permanentlyDelete = (e: any) => {
    setDialogBox("");
    setLoading("permanentlyDelete");
    const type = props.fileDetail.type === "folder" ? "folders" : "files";
    Service.patch(`${type}/${props.fileDetail.id}`, {
      type: "delete",
    }).then((response: any) => {
      setLoading("");
      props.setFiles(
        props.files.filter((file: any) => file.id !== props.fileDetail.id)
      );
      props.onFolder(
        props.folders.filter((file: any) => file.id !== props.fileDetail.id)
      );
      props.closeSidebar();
    });
  };

  const restore = (e: any) => {
    setLoading("restore");
    const type = props.fileDetail.type === "folder" ? "folders" : "files";
    Service.patch(`${type}/${props.fileDetail.id}`, {
      type: "restore",
    }).then((response: any) => {
      setLoading("");
      props.setFiles(
        props.files.filter((file: any) => file.id !== props.fileDetail.id)
      );
      props.onFolder(
        props.folders.filter((file: any) => file.id !== props.fileDetail.id)
      );
      props.closeSidebar();
    });
  };

  const processBulkDownload = () => {
    Service.post(`glaceir/initiateJob/bulk/${props.fileDetail.id}`)
      .then((response) => {
        setLoading("");
        props.updateFileDetail({
          ...props.fileDetail,
          status: 1,
        });
        props.updateFolderStatus(props.fileDetail.id, 1);
      })
      .catch((error) => {
        setLoading("");
        enqueueSnackbar("This folder is empty!", {
          key: new Date().getTime() + Math.random(),
          variant: "error",
        });
      });
  };

  const requestDownload = () => {
    setLoading("requestDownload");
    if (props.fileDetail.type === "folder") {
      return processBulkDownload();
    }
    Service.post(`glaceir/initiateJob`, {
      file_id: props.fileDetail.id,
    })
      .then((response) => {
        setLoading("");
        props.updateFileDetail({
          ...props.fileDetail,
          requested_at: new Date(),
          status: 1,
        });
        props.updateFileStatus(props.fileDetail.id, 1);
      })
      .catch((error) => {
        setLoading("");
      });
  };

  const isFileExists = () => {
    if (typeof props.fileDetail.thumbnail !== "string") {
      return false;
    }
    return (
      (props.fileDetail.thumbnail || "").match(
        /^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gim
      ) != null
    );
  };

  const getLocation = () => {
    if (props.fileDetail.deleted_at !== null) {
      return "Trash";
    }

    if (props.fileDetail.folder) {
      if (props.fileDetail.folder.name === "default") {
        return "All Files";
      } else {
        return props.fileDetail.folder.name;
      }
    }
    return "";
  };

  const header = () => {
    return (
      <Grid container direction="row" alignItems="center">
        <Grid item className="icon-container margin">
          {props.fileDetail.type === "folder" ? (
            <Icon.Folder />
          ) : (
            <img
              src={getFileTypeIcon(props.fileDetail.type)}
              alt={props.fileDetail.name}
            />
          )}
        </Grid>
        <Grid item xs>
          <Box
            overflow="hidden"
            textOverflow="ellipsis"
            className={classes.headerRoot}
          >
            <Typography variant="h5" noWrap className="f14">
              {props.fileDetail.name}
            </Typography>
          </Box>
        </Grid>
        <Grid item style={{ display: "flex" }} xs={1}>
          <Icon.Cancel
            className="pointer default-icon"
            onClick={() => props.closeSidebar()}
          />
        </Grid>
      </Grid>
    );
  };

  const currentStatus = () => {
    return (
      <Grid container direction="row" alignItems="center">
        <Grid item className="icon-container margin">
          {props.fileDetail.status === 1 ? (
            <Icon.Requested className="yello-icon" />
          ) : (
            ProcessFileIcon[props.fileDetail.status]
          )}
        </Grid>
        <Grid item xs>
          <Typography variant="h5" className="f14">
            {FileStatus[props.fileDetail.status]}
          </Typography>
        </Grid>
        <Box mt={2} />
      </Grid>
    );
  };

  return (
    <>
      {props.dialog === "FILE_DETAIL" && (
        <div id="file-detail-bg" className={classes.root}></div>
      )}
      <Slide direction="left" in={props.dialog === "FILE_DETAIL"}>
        {props.fileDetail && (
          <div className={classes.container} id="file-detail-container">
            <Box mt={2} mb={2}>
              {header()}
            </Box>
            {props.fileDetail.deleted_at === null && (
              <Box mb={2}>{currentStatus()}</Box>
            )}
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={2}
              className={classes.middleContainer}
            >
              {isFileExists() && (
                <Grid item xs={12} className={classes.previewContainer}>
                  <img
                    src={props.fileDetail.thumbnail || "test.jpg"}
                    alt="thumbnail"
                    className={classes.preview}
                    onError={(event: any) =>
                      (event.target.style.display = "none")
                    }
                    onLoad={(event: any) =>
                      (event.target.style.display = "inline-block")
                    }
                  />
                </Grid>
              )}
              {props.fileDetail.deleted_at !== null && (
                <>
                  <Grid item xs={5}>
                    <Typography variant="body2">Deleted in</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="h5" className="f14">
                      {deletedInDays()}
                    </Typography>
                  </Grid>
                </>
              )}

              <Grid item xs={5}>
                <Typography variant="body2">Type</Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="h5" className="f14">
                  {props.fileDetail.type === "folder"
                    ? "Folder"
                    : "." + getFileExt(props.fileDetail.name)}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="body2">Size</Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="h5" className="f14">
                  {bytesToSize(props.fileDetail.size)}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="body2">Uploaded</Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="h5" className="f14">
                  {formateDate(new Date(props.fileDetail.created_at))}
                </Typography>
              </Grid>
              {props.fileDetail.deleted_at === null &&
                props.fileDetail.requested_at && (
                  <>
                    <Grid item xs={5}>
                      <Typography variant="body2">Last Requested</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant="h5" className="f14">
                        {formateDate(new Date(props.fileDetail.requested_at))}
                      </Typography>
                    </Grid>
                  </>
                )}
              <Grid item xs={5}>
                <Typography variant="body2">Location</Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="h5" className="f14">
                  {getLocation()}
                </Typography>
              </Grid>
            </Grid>
            <Box mt={3} />

            {props.fileDetail.deleted_at !== null ? (
              <>
                <PrimaryButton
                  fullWidth
                  onClick={restore}
                  loading={loading === "restore"}
                >
                  <Icon.Restore className="white-icon" /> &nbsp; &nbsp; Restore
                </PrimaryButton>
                <Box mt={1} />
                <DangerButton
                  fullWidth
                  onClick={confirmPermanentlyDelete}
                  loading={loading === "permanentlyDelete"}
                >
                  <Icon.Trash className="white-icon" />
                  &nbsp; &nbsp; Permanently delete
                </DangerButton>
              </>
            ) : props.fileDetail.status === 0 ? (
              <PrimaryButton
                fullWidth
                onClick={requestDownload}
                loading={loading === "requestDownload"}
              >
                <img
                  src={WhiteFileIcon[props.fileDetail.status]}
                  alt="request"
                />{" "}
                &nbsp; &nbsp; Request
              </PrimaryButton>
            ) : [1, 11].indexOf(props.fileDetail.status) !== -1 ? (
              <PrimaryButton fullWidth disabled={true}>
                <Icon.Requested className="yello-icon" />
                &nbsp; &nbsp; Requested
              </PrimaryButton>
            ) : [2, 12, 4].indexOf(props.fileDetail.status) !== -1 ? (
              <PrimaryButton fullWidth onClick={download}>
                <img
                  src={WhiteFileIcon[props.fileDetail.status]}
                  alt="Download"
                />{" "}
                &nbsp; &nbsp; Download
              </PrimaryButton>
            ) : null}

            <Box mt={2} />
            {props.fileDetail.deleted_at === null &&
              [1, 11].indexOf(props.fileDetail.status) !== -1 && (
                <Typography variant="body2">
                  Your file has been requested. You will recieve an email when
                  it is ready to download.
                </Typography>
              )}
          </div>
        )}
      </Slide>
      <ConfirmationDialog
        data={props.fileDetail}
        loading={loading === "permanentlyDelete"}
        open={dialogBox === "permanentlyDelete"}
        onConfirm={permanentlyDelete}
        handleClose={() => setDialogBox("")}
      />
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    files: state.files,
    dialog: state.dialog,
    folders: state.folders,
    fileDetail: state.fileDetail,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    closeSidebar: () => dispatch({ type: "CLOSE_RIGHT_SIDEBAR", name: "" }),
    setFiles: (files: any) => dispatch({ type: "SET_FILES", files: files }),
    updateFileDetail: (file: any) =>
      dispatch({ type: "SET_FILE_DETAIL", fileDetail: file }),
    updateFileStatus: (id: any, status: number) =>
      dispatch({ type: "SET_FILE_STATUS", id: id, status: status }),
    updateFolderStatus: (id: any, status: number) =>
      dispatch({ type: "SET_FOLDER_STATUS", id: id, status: status }),
    onFolder: (folders: any) =>
      dispatch({ type: "SET_FOLDER", folders: folders }),
    uploadFile: (file: any) =>
      dispatch({ type: "SET_FILE_LOADER", file: file }),
    removeUploadFile: (id: string) =>
      dispatch({ type: "UPDATE_FILE_LOADER", id: id }),
    updateRetrival: (size: number) =>
      dispatch({ type: "UPDATE_RETRIVAL", size: size }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RightSidebarDetail);
