import {
  makeStyles,
  CircularProgress,
} from "@material-ui/core";

const circularStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    margin: "0px 4px",
    display: "flex",
    alignItems: "center",
  },
  bottom: {
    color: "#2C3245",
  },
  top: {
    color: "#EAEAEA",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
}));

function MyCircularProgress(props: any) {
  const classes = circularStyles();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        className={classes.top}
        disableShrink
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </div>
  );
}

export default MyCircularProgress;