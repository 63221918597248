import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
} from "@material-ui/core";
import React from "react";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import PrimaryButton from "../Component/PrimaryButton";
import PrimaryInput from "../Component/PrimaryInput";
import ErrorMessage from "../Component/ErrorMessage";
import { Service, errorResponseHandler } from "../Services/Service";
import { validEmail } from "../Library/Validation";

function CreateWhitelist(props: any) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    reset({
      email: "",
    });
    props.closeDialog();
  };

  const onSubmit = (data: any) => {
    setLoading(true);
    Service.post("whitelist", {
      email: data.email.trim(),
    })
      .then((response) => {
        setLoading(false);
        enqueueSnackbar(response.data.message, {
          key: new Date().getTime() + Math.random(),
          variant: "success",
        });
        handleClose();
      })
      .catch((error) => {
        setLoading(false);
        if (errorResponseHandler(error, enqueueSnackbar)) {
          enqueueSnackbar(error.response.data.message || "Oops! Something went wrong", {
            key: new Date().getTime() + Math.random(),
            variant: "error",
          });
        }
        handleClose();
      });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={props.dialog === "CREATE_WHITELIST"}
      onClose={handleClose}
    >
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Create</DialogTitle>
        <DialogContent>
          <FormControl>
            <PrimaryInput
              fullWidth
              placeholder="Email"
              {...register("email", {
                required: true,
                validate: {
                  email: (value) => validEmail(value),
                },
              })}
            />
            <ErrorMessage errors={errors.email} />
          </FormControl>
        </DialogContent>
        <Box p={2} pr={2} pt={0}>
          <DialogActions>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item xs={12} sm={3}>
                <PrimaryButton
                  fullWidth
                  variant="outlined"
                  onClick={handleClose}
                >
                  Cancel
                </PrimaryButton>
              </Grid>

              <Grid item xs={12} sm={3}>
                <PrimaryButton type="submit" fullWidth loading={loading}>
                  Create
                </PrimaryButton>
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
      </form>
    </Dialog>
  );
}

const mapStateToProps = (state: any) => {
  return {
    dialog: state.dialog,
    defaultFolder: state.defaultFolder,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    closeDialog: () => dispatch({ type: "SET_POPUP", name: "" }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateWhitelist);
