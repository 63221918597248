import { Menu, MenuItem, Divider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const ContextMenu = withStyles({
  paper: {
    borderRadius: "10px",
    padding: "0px 16px",
    minWidth: "200px",
    boxShadow: "none",
    border: "solid 1px #EAEAEA"
  },
})(Menu);

const ContextMenuItem = withStyles({
  root: {
    paddingTop: '10px',
    paddingBottom: '10px',
    fontSize: "14px",
    fontWeight: 500,
    paddingLeft: "2px"
  },
})(MenuItem);

const ContextMenuDivider = () => {
  return <Divider style={{ margin: "0px 0px", color: "#EAEAEA" }} />;
};

export { ContextMenu, ContextMenuItem, ContextMenuDivider };
