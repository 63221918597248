import { Grid } from "@material-ui/core";
import MyCircularProgress from "./MyCircularProgress";

const InlinePageLoader = (props: any) => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ height: "calc(100vh - 200px)" }}
    >
      <MyCircularProgress size="22px" />
    </Grid>
  );
};

export { InlinePageLoader };
