import { Service } from "../Services/Service";
import { onDeleteCloseRightSidebar } from "./Other";

export const initiateDeleteFile = (fileObject) => {
  return (dispatch) => {
    Service.delete(`files/${fileObject?.id}`)
      .then((response) => {
        dispatch(removeFileFromList(fileObject.id));
        dispatch(onDeleteCloseRightSidebar(fileObject.id));
      })
      .catch((error) => {});
  };
};

export const forceDeleteFile = (fileObject) => {
  return (dispatch) => {
    Service.patch(`files/${fileObject?.id}`, { type: "delete" })
      .then((response) => {
        dispatch(removeFileFromList(fileObject.id));
        dispatch(onDeleteCloseRightSidebar(fileObject.id));
      })
      .catch((error) => {});
  };
};

export const removeFileFromList = (ids) => {
  return {
    type: "REMOVE_FILE_FROM_LIST",
    ids,
  };
};
