import cloudIcom from "../images/cloud.svg";
import whiteCloudIcom from "../images/white/cloud.svg";
import waitingIcon from "../images/waiting.svg";
import downloadGreenIcon from "../images/download_green.svg";
import downloadWhiteIcon from "../images/download_white.svg";
import * as Icon from "../Icon/index";

const FileStatus = ["Stored", "Requested", "Available", "Download"];
const DefaultFileIcon = [cloudIcom, waitingIcon, downloadGreenIcon, cloudIcom];
const WhiteFileIcon = [whiteCloudIcom, waitingIcon, downloadWhiteIcon, whiteCloudIcom, downloadWhiteIcon];

const ProcessFileIcon = [
  <Icon.Cloud />,
  <Icon.Waiting />,
  <Icon.Download className="green-icon" />,
  <Icon.Cloud />,
];

enum FileState {
  STORED = 0,
  REQUESTED = 1,
  AVAILABLE = 2,
  DOWNLOADED = 4,
  DELETED = 5
}

export {
  FileState,
  ProcessFileIcon,
  DefaultFileIcon,
  FileStatus,
  WhiteFileIcon,
};
