import React from "react";
import { useParams, Link } from "react-router-dom";
import { Typography, Breadcrumbs, Box } from "@material-ui/core";
import { Service } from "../Services/Service";

function PageBreadcrumb(props: any) {
  const urlParams: { id: string } = useParams();
  const [folders, setFolders] = React.useState<
    {
      id: string;
      name: string;
    }[]
  >([]);

  React.useEffect(() => {
    let id = urlParams.id;
    if (id) {
      Service.get(`get-breadcrumb/${id}`)
        .then((response) => {
          if (response.data.data.length > 0) {
            response.data.data.shift();
          }
          setFolders(response.data.data);
        })
        .catch((error) => {});
    }
  }, [urlParams.id]);

  return (
    <Box mb={2}>
      <Breadcrumbs maxItems={4} separator="›" aria-label="breadcrumb">
        <Link key="1" color="inherit" to={"/"} className="normalize-anchor">
          <Typography variant="body2">{props.start}</Typography>
        </Link>
        {folders.map((folder, index) => (
          <Link
            key={"readcrum-" + index}
            color="inherit"
            to={`/${props.urlPrefix ? props.urlPrefix : "folder"}/${folder.id}`}
            className="normalize-anchor"
          >
            <Typography variant="body2">{folder.name}</Typography>
          </Link>
        ))}
      </Breadcrumbs>
    </Box>
  );
}

export { PageBreadcrumb };
