import React from "react";
import { setCookie } from "../Library/Cookies";
import { StorageKey } from "../Config/StorageKey";

const IdealTimeHandler = (props: any) => {
  React.useEffect(() => {
    let idleTime = 0;

    setInterval(() => {
      idleTime = idleTime + 1;
      if (idleTime > 60) {
        // 20 minutes
        window.location.reload();
      }
    }, 60000); // 1 minute

    document.body.addEventListener("mousemove", function (e) {
      idleTime = 0;
    });

    document.body.addEventListener("keypress", function (e) {
      idleTime = 0;
    });

    setInterval(() => {
      if (idleTime === 0) {
        setCookie(StorageKey.USER, localStorage.getItem(StorageKey.USER), 1);
      }
    }, 10 * 60000);
  }, []);

  return <div></div>;
};

export default IdealTimeHandler;
