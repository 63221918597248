import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { Service } from "../Services/Service";
import RequestDownload from "./RequestDownload";
import { generateThumbnail, generateVideoThumbnail } from "../Library/Image";
import {
  bytesToSize,
  getFileTypeIcon,
  getFileType,
  formateDate,
} from "../Helper/Common";
import * as Icon from "../Icon/index";

function useSingleAndDoubleClick(
  actionWithoutDelay: any,
  actionSimpleClick: any,
  actionDoubleClick: any,
  delay = 250
) {
  const [click, setClick] = React.useState(0);

  React.useEffect(() => {
    if (click === 1) {
      actionWithoutDelay();
    }
    const timer = setTimeout(() => {
      // simple click
      if (click === 1) {
        if (window.outerWidth > 400) {
          actionSimpleClick();
        } else {
          actionDoubleClick();
        }
      }
      setClick(0);
    }, delay);

    // the duration between this click and the previous one
    // is less than the value of delay = double-click
    if (click === 2) actionDoubleClick();

    return () => clearTimeout(timer);
    //eslint-disable-next-line
  }, [click]);

  return () => setClick((prev) => prev + 1);
}

function FolderRowComponent(props: any) {
  const history = useHistory();
  const [isActive, setIsActive] = React.useState(false);
  const click = useSingleAndDoubleClick(
    () => {
      setIsActive(true);
      if (props.fileDetail.name) {
        props.setFileDetail({
          type: "folder",
          ...props.value,
        });
      }
    },
    () => {
      props.setFileDetail({
        type: "folder",
        ...props.value,
      });
      setIsActive(false);
    },
    () => {
      setIsActive(false);
      if (props.disableNavigation !== true) {
        const prefix = props.urlPrefix ? props.urlPrefix : "folder";
        history.push({
          pathname: `/${prefix}/${props.value.id}`,
          search: `?secret=${Math.random()}`,
        });
      }
    }
  );

  const availableIn = () => {
    const day = moment().diff(
      moment(moment(props.value.available_at).subtract(3, "day")),
      "days"
    );
    if (day > 1) {
      return day + " days";
    }
    return "Less than 1 day";
  };

  const onDragLeave = (event: any) => {
    if (isActive) {
      setIsActive(false);
    }
  };

  const onDragEnter = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    if (!isActive) {
      setIsActive(true);
    }
  };

  const onDrop = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    uploadFiles(files);
    setIsActive(false);
  };

  const uploadFiles = async (files: any) => {
    console.log("files", files);
    files.forEach((file: any) => {
      switch (getFileType(file.type)) {
        case "image":
          generateThumbnail(file, [300, 300]).then((preview: any) => {
            uploadFile(file, preview);
          });
          break;
        case "video":
          generateVideoThumbnail(file).then((preview: any) => {
            uploadFile(file, preview);
          });
          break;
        default:
          uploadFile(file, "");
          break;
      }
    });
  };

  const uploadFile = async (file: any, preview: any) => {
    const uploadFileId = Math.random();
    let percent = 5;
    const dropZone = document.body;

    props.updateStorage(file.size);
    dropZone.classList.remove("hover");

    props.uploadFile({
      id: uploadFileId,
      name: file.name,
      percent: percent,
      type: file.type,
    });

    let interval = setInterval(() => {
      if (percent < 50) {
        percent = percent + 5;
      } else if (percent < 97) {
        percent = percent + 1;
      }
      props.uploadFile({
        id: uploadFileId,
        percent: percent,
      });
    }, 1000);

    const formData = new FormData();
    formData.append("folder_id", props.value.id);
    formData.append("url", "a");
    formData.append("name", file.name);
    formData.append("size", file.size);
    formData.append("type", file.type);
    formData.append("buffer", file);

    if (preview) {
      formData.append("thumbnail", preview);
    }

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    Service.post("/glaceir/uploadArchive", formData, config)
      .then((response: any) => {
        props.removeUploadFile(uploadFileId);
        clearInterval(interval);
      })
      .catch((error) => {
        props.removeUploadFile(uploadFileId);
      });
  };

  const deletedInDays = () => {
    const days = moment(props.value.deleted_at)
      .startOf("day")
      .diff(moment().subtract(30, "day").startOf("day"), "days");
    return days > 1 ? `${days} days` : "1 day";
  };

  const getClassName = () => {
    if (props.fileDetail) {
      if (props.fileDetail.id === props.value.id) {
        return "active";
      }
    }
    return "";
  };

  return (
    <>
      <div
        onClick={click}
        className={`customGrid pointer folder-row ${getClassName()} ${
          isActive ? "active" : ""
        }`}
        style={{ height: "60px" }}
      >
        <div
          className="styled-div"
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        ></div>
        <div
          className="fileName pl-3"
          onContextMenu={(e: any) =>
            props.handleContextMenu(e, {
              id: props.value.id,
              type: "folder",
              status: props.value.status,
              created_at: props.value.created_at,
            })
          }
        >
          <div className="ListIcon">
            <Icon.Folder />
          </div>
          <div className="fileNameContent">
            <Typography variant="body2" style={{ fontWeight: 500 }}>
              {props.value.name}
            </Typography>
          </div>
        </div>
        <div className="AddedDate">
          <Typography noWrap variant="body2" color="textSecondary">
            {formateDate(new Date(props.value.created_at))}
          </Typography>
        </div>
        <div className="fileSize">
          <Typography noWrap variant="body2" color="textSecondary">
            {bytesToSize(props.value.size)}
          </Typography>
        </div>
        <div className="action">
          {props.page === "available" ? (
            <Typography variant="body2" color="textSecondary">
              {availableIn()}
            </Typography>
          ) : props.page === "requested" ? (
            <Typography variant="body2" color="textSecondary">
              1 Day
            </Typography>
          ) : props.value.deleted_at !== null ? (
            <Typography variant="body2" color="textSecondary">
              {deletedInDays()}
            </Typography>
          ) : [1, 11].indexOf(props.value.status) !== -1 ? (
            <Icon.Waiting className="yello-icon" />
          ) : [2, 12].indexOf(props.value.status) !== -1 ? (
            <Icon.Download className="green-icon" />
          ) : (
            <Icon.Cloud />
          )}
        </div>
      </div>
    </>
  );
}

function FileRowComponent(props: any) {
  const deletedInDays = () => {
    const days = moment(props.value.deleted_at)
      .startOf("day")
      .diff(moment().subtract(30, "day").startOf("day"), "days");
    return days > 1 ? `${days} days` : "1 day";
  };

  const availableIn = () => {
    if (!props.value.available_at) {
      return "1 day";
    }
    const days = moment(props.value.available_at)
      .startOf("day")
      .diff(moment().subtract(3, "day").startOf("day"), "days");
    return days > 1 ? `${days} days` : "Less than 1 day";
  };

  const getClassName = () => {
    if (props.fileDetail && props.fileDetail.id) {
      if (props.fileDetail.id === props.value.id) {
        return "file-row pointer active";
      }
    }
    return "pointer file-row";
  };

  return (
    <div
      style={{ height: "60px" }}
      onClick={() => props.setFileDetail({...props.value})}
      className={`customGrid pointer ${getClassName()}`}
      onContextMenu={(e) =>
        props.handleContextMenu(e, {
          id: props.value.id,
          type: "file",
          status: props.value.status,
          created_at: props.value.created_at,
        })
      }
    >
      <div className="fileName pl-3">
        <div className="ListIcon">
          <img
            className="icon"
            src={getFileTypeIcon(props.value.type)}
            alt={props.value.name}
          />
        </div>
        <div className="fileNameContent">
          <Typography variant="body2" style={{ fontWeight: 500 }}>
            {props.value.name}
          </Typography>
        </div>
      </div>
      <div className="AddedDate">
        <Typography noWrap variant="body2" color="textSecondary">
          {formateDate(new Date(props.value.created_at))}
        </Typography>
      </div>
      <div className="fileSize">
        <Typography noWrap variant="body2" color="textSecondary">
          {bytesToSize(props.value.size)}
        </Typography>
      </div>
      <div className="action">
        {props.page === "available" ? (
          <Typography variant="body2" color="textSecondary">
            {availableIn()}
          </Typography>
        ) : props.page === "requested" ? (
          <Typography variant="body2" color="textSecondary">
            1 Day
          </Typography>
        ) : props.value.deleted_at !== null ? (
          <Typography variant="body2" color="textSecondary">
            {deletedInDays()}
          </Typography>
        ) : [1, 11].indexOf(props.value.status) !== -1 ? (
          <Icon.Waiting className="yello-icon" />
        ) : [2, 4, 12].indexOf(props.value.status) !== -1 ? (
          <Icon.Download className="green-icon" />
        ) : (
          <RequestDownload id={props.value.id} disable={true} />
        )}
      </div>
    </div>
  );
}

function RowHeader(props: any) {
  return (
    <div className="customGrid header">
      <div className="fileName">
        <Typography variant="body2" className="dark-grey">
          Name
        </Typography>
      </div>
      <div className="AddedDate">
        <Typography variant="body2" className="dark-grey">
          Added
        </Typography>
      </div>
      <div className="fileSize">
        <Typography variant="body2" className="dark-grey">
          Size
        </Typography>
      </div>
      <div className="action">
        <Typography variant="body2" className="dark-grey">
          {props.status ? props.status : "Status"}
        </Typography>
      </div>
    </div>
  );
}

function ActionHeader(props: any) {
  return (
    <Typography variant="body2" className="dark-grey">
      {props.label}
    </Typography>
  );
}

const mapStateToProps = (state: any) => {
  return {
    dialog: state.dialog,
    defaultFolder: state.defaultFolder,
    fileDetail: state.fileDetail,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onFolder: (folders: any) =>
      dispatch({ type: "SET_FOLDER", folders: folders }),
    setFiles: (files: any) => dispatch({ type: "SET_FILES", files: files }),
    uploadFile: (file: any) =>
      dispatch({ type: "SET_FILE_LOADER", file: file }),
    removeUploadFile: (id: string) =>
      dispatch({ type: "UPDATE_FILE_LOADER", id: id }),
    updateStorage: (size: number) =>
      dispatch({ type: "UPDATE_STORAGE", size: size }),
    setFileDetail: (fileDetail: any) =>
      dispatch({ type: "SET_FILE_DETAIL", fileDetail: fileDetail }),
    closeSidebar: () => dispatch({ type: "CLOSE_RIGHT_SIDEBAR", name: "" }),
  };
};

const FolderRow = connect(
  mapStateToProps,
  mapDispatchToProps
)(FolderRowComponent);

const FileRow = connect(mapStateToProps, mapDispatchToProps)(FileRowComponent);

export { FolderRow, FileRow, RowHeader, ActionHeader };
