import { connect } from "react-redux";
import HeaderTopNotification from "./HeaderTopNotification";

function HeaderHeight(props: any) {
  return (
    <>
      <HeaderTopNotification />
      <div style={{ height: "64px", width: "100%" }}></div>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(HeaderHeight);
