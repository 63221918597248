import { StorageKey } from "../Config/StorageKey";
import { setCookie, getCookie } from "../Library/Cookies";
import { User } from "../Models/User";

interface IFlashMessage {
  title?: string;
  body: string;
  variant: string;
}

interface IPaginate {
  total: number;
}

interface Subscription {
  plan: number;
  free_download: number;
  plan_duration: number;
  download_price: number;
  next_billing_date: Date;
}

type Folder = {
  id: number;
  name: string;
};

type UploadFile = {
  id: number;
  name: string;
  type?: string;
  item_type?: "single" | "batch" | "none" | "zip";
  percent: number;
  folderId?: string;
  status?: "active" | "canceled" | "failed" | "success";
};

type FileDetail = {
  id: string;
  name: string;
  size: number;
  folder_id: string;
  s3_bucket_id: string;
  status: number;
  thumbnail: string;
  type: string;
  url: string;
  created_at: string;
  deleted_at?: string;
  requested_at: string;
  available_at?: string;
  folder: Folder;
};

type DialogButton = {
  label: string;
};

type DialogObject = {
  type?: string;
  name: string;
  title: string;
  description: string;
  loader: false;
  onConfirmation: () => void;
  buttons?: DialogButton[];
};

type WorkerState = {
  processID: string;
  status: string;
};

type Country = {
  id: number;
  name: string;
  code: string;
  status?: number;
};

type State = {
  id: number;
  name: string;
  status?: number;
};

// type StateList = {
//   [key: string]: State[];
// };

type ApplicationState = {
  experience: number;
  isLoggedIn: boolean;
  user?: User;
  flashMessages: IFlashMessage[];
  dialog: string;
  defaultFolder: Folder;
  folders: any[];
  files: FileDetail[];
  loader: boolean;
  uploadFile: UploadFile[];
  fileDetail: FileDetail;
  dialogObject: DialogObject;
  workerState: WorkerState;
  subscription?: Subscription;
  countries: Country[];
  states: State[];
  users: User[];
  paginate: IPaginate;
};

const initialState: ApplicationState = {
  loader: false,
  experience: 7,
  isLoggedIn: false,
  flashMessages: [],
  folders: [],
  files: [],
  dialog: "",
  dialogObject: {
    name: "",
    title: "",
    description: "",
    onConfirmation: () => {},
    loader: false,
  },
  defaultFolder: {
    id: 0,
    name: "",
  },
  uploadFile: [],
  fileDetail: {
    id: "",
    name: "",
    size: 0,
    folder_id: "",
    s3_bucket_id: "",
    status: 0,
    thumbnail: "",
    type: "",
    url: "",
    created_at: "",
    deleted_at: "",
    requested_at: "",
    folder: {
      id: 0,
      name: "",
    },
  },
  workerState: {
    processID: "",
    status: "",
  },
  subscription: {
    plan: 0,
    free_download: 0,
    plan_duration: 1,
    download_price: 0,
    next_billing_date: new Date("1970-01-01"),
  },
  countries: [],
  states: [],
  users: [],
  paginate: {
    total: 0,
  },
};

let user: any = localStorage.getItem(StorageKey.USER);
let isSessionExpire: any = true;
if (user) {
  if (JSON.parse(user).stay_signed_in !== 1) {
    if (!getCookie(StorageKey.USER)) {
      isSessionExpire = false;
    }
  }

  if (isSessionExpire) {
    initialState.isLoggedIn = true;
    initialState.user = JSON.parse(user);
    initialState.defaultFolder = initialState.user?.folder;
  }
}

const reducer = (state = initialState, action: any) => {
  const newState = { ...state };

  switch (action.type) {
    case "ON_DELETE_CLOSE_RIGHT_SIDEBAR":
      if (newState.fileDetail && newState.fileDetail.id === action?.id) {
        newState.dialog = "";
      }
      break;
    case "REMOVE_FILE_FROM_LIST":
      newState.files = [
        ...newState.files.filter((file: any) => file.id !== action?.ids),
      ];
      break;
    case "REMOVE_FOLDER_FROM_LIST":
      newState.folders = [
        ...newState.folders.filter((folder: any) => folder.id !== action?.ids),
      ];
      break;
    case "EXPERIENCE_UP":
      newState.isLoggedIn = true;
      break;
    case "UPDATE_STORAGE":
      if (newState.user) {
        newState.user = {
          ...newState.user,
          used_storage: newState.user.used_storage + action.size,
        };
        localStorage.setItem(StorageKey.USER, JSON.stringify(newState.user));
      }
      break;
    case "UPDATE_RETRIVAL":
      if (newState.user) {
        newState.user = {
          ...newState.user,
          total_request: newState.user.total_request + action.size,
        };
        localStorage.setItem(StorageKey.USER, JSON.stringify(newState.user));
      }
      break;
    case "SET_LOADER":
      newState.loader = action.loader;
      break;
    case "SET_DIALOG_OBJECT":
      newState.dialog = action.dialogObject.name;
      newState.dialogObject = {
        name: "",
        title: "",
        description: "",
        onConfirmation: () => {},
        loader: false,
      };
      newState.dialogObject = action.dialogObject;
      break;
    case "SET_FILE_STATUS":
      {
        let index = newState.files.findIndex(
          (file: any) => file.id === action.id
        );

        if (index !== -1) {
          newState.files[index].status = action.status;
          newState.files[index].requested_at = new Date().toString();

          // update size
          if (newState.user && action.status === 1) {
            newState.user = {
              ...newState.user,
              total_request:
                newState.user.total_request + newState.files[index].size,
            };
            localStorage.setItem(
              StorageKey.USER,
              JSON.stringify(newState.user)
            );
          }
        }
        newState.files = JSON.parse(JSON.stringify(newState.files));
      }
      break;

    case "SET_FOLDER_STATUS":
      {
        let index = newState.folders.findIndex(
          (file: any) => file.id === action.id
        );

        if (index !== -1) {
          newState.folders[index].status = action.status;
          newState.folders[index].requested_at = new Date().toString();

          // update size
          if (newState.user && action.status === 1) {
            newState.user = {
              ...newState.user,
              total_request:
                newState.user.total_request + newState.folders[index].size,
            };
            localStorage.setItem(
              StorageKey.USER,
              JSON.stringify(newState.user)
            );
          }
        }
        newState.folders = JSON.parse(JSON.stringify(newState.folders));
      }
      break;

    case "SET_FILE_LOADER":
      {
        let index = newState.uploadFile.findIndex(
          (file) => file.id === action.file.id
        );
        if (index === -1) {
          newState.uploadFile.push(action.file);
        } else {
          newState.uploadFile[index] = {
            ...newState.uploadFile[index],
            ...action.file,
          };
        }
        newState.uploadFile = JSON.parse(JSON.stringify(newState.uploadFile));
      }
      break;
    case "REMOVE_FILE_LOADER":
      newState.uploadFile = [];
      break;
    case "CHANGE_WORKER":
      newState.workerState = action.workerState;
      break;
    case "SET_POPUP":
      newState.dialog = action.name;
      break;
    case "CLOSE_RIGHT_SIDEBAR":
      newState.dialog = "";
      newState.fileDetail.url = "";
      newState.fileDetail.id = "";
      break;
    case "SET_FOLDER":
      newState.folders = action.folders;
      break;
    case "SET_FILES":
      newState.files = action.files;
      break;
    case "SET_FILE_DETAIL":
      newState.fileDetail = action.fileDetail;
      newState.dialog = "FILE_DETAIL";
      break;
    case "UPDATE_FILE_LOADER":
      {
        let index = newState.uploadFile.findIndex(
          (file) => file.id === action.id
        );
        newState.uploadFile[index].percent = 100;
        newState.uploadFile[index].status = "success";
        newState.uploadFile = JSON.parse(JSON.stringify(newState.uploadFile));
      }
      break;
    case "RESET_FILE_DETAIL":
      newState.fileDetail = {
        id: "",
        name: "",
        size: 0,
        folder_id: "",
        s3_bucket_id: "",
        status: 0,
        thumbnail: "",
        type: "",
        url: "",
        created_at: "",
        deleted_at: "",
        requested_at: "",
        folder: {
          id: 0,
          name: "",
        },
      };
      break;

    case "SET_LOGGIN_USER":
      newState.user = action.user;
      newState.defaultFolder = action.user?.folder;
      newState.isLoggedIn = true;
      localStorage.setItem(StorageKey.USER, JSON.stringify(action.user));
      if (action.user.stay_signed_in === 1) {
        setCookie(StorageKey.USER, JSON.stringify(action.user), 24 * 30 * 12);
      } else {
        setCookie(StorageKey.USER, JSON.stringify(action.user), 1);
      }
      break;
    case "SET_USER":
      if (newState.user) {
        newState.user = {
          ...newState.user,
          ...action.user,
          token: newState.user.token,
        };
      }
      break;

    case "SET_COUNTRY":
      newState.countries = action.countries;
      break;
    case "SET_STATE":
      console.log("action", action);
      newState.states = action.states;
      // newState.states[action.country_id] = action.states;
      break;

    case "SET_USER_LIST":
      newState.users = action.paginate.records;
      newState.paginate = {
        total: action.paginate.total
      };
      break;
  }

  return newState;
};

export default reducer;
