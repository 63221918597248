import React from "react";
import {
  Box,
  Typography,
  Grid,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Service, errorResponseHandler } from "../Services/Service";
import PrimaryButton from "../Component/PrimaryButton";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";

function UploadProfilePicture(props: any) {
  const [resultContent, setResultContent] = React.useState("");
  const [file, setFile] = React.useState("");
  const [activeDrag, setActiveDrag] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    props.setPopup("");
    setResultContent("");
    setLoading(false);
  };

  const onDrop = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setActiveDrag(false);
    setFile(event.target.files[0]);
    const url = URL.createObjectURL(event.target.files[0]);
    setResultContent(url);
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("profile_image", file);
    formData.append("type", "change");

    Service.post("profile/changeImage", formData)
      .then((response) => {
        setLoading(false);
        props.setUser({
          ...props.user,
          profile_image: response.data.profile_image,
        });
        props.setPopup("");
        enqueueSnackbar(response.data.message, {
          key: new Date().getTime() + Math.random(),
          variant: "success",
        });
      })
      .catch((error) => {
        setLoading(false);
        if (errorResponseHandler(error, enqueueSnackbar)) {
          enqueueSnackbar(error.response.data.message, {
            key: new Date().getTime() + Math.random(),
            variant: "error",
          });
        }
      });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={props.dialog === "change_photo"}
      onClose={handleClose}
    >
      <DialogTitle>Update Profile Photo</DialogTitle>
      <DialogContent>
        {resultContent ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Avatar
              alt="Profile"
              src={resultContent}
              style={{ width: "150px", height: "150px" }}
            />
            <Box mb={2} />
            <Typography variant="h6" color="textPrimary">
              <b>Your new profile photo</b>
            </Typography>
          </Box>
        ) : (
          <Box
            className="init-dropzone"
            display="flex"
            alignItems="center"
            justifyContent="center"
            onDragOver={() => setActiveDrag(true)}
            onDragEnter={() => setActiveDrag(true)}
            onDragLeave={() => setActiveDrag(false)}
          >
            <input
              type="file"
              accept="image/*"
              className="dropable-file"
              onChange={onDrop}
            />
            {activeDrag ? (
              <Typography color="textPrimary">Drop to upload</Typography>
            ) : (
              <Typography color="textPrimary">
                Drag and drop or{" "}
                <span className="underline pointer">upload from computer</span>
              </Typography>
            )}
          </Box>
        )}
      </DialogContent>

      <Box mb={2} pr={2} mt={2}>
        <DialogActions>
          <Grid container justifyContent="flex-end" spacing={2}>
            {!resultContent ? (
              <Grid item xs={3}>
                <PrimaryButton variant="outlined" onClick={handleClose}>
                  Cancel
                </PrimaryButton>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} sm="auto">
                  <PrimaryButton
                    variant="outlined"
                    className="smFullWidth"
                    onClick={() => setResultContent("")}
                  >
                    Choose another photo
                  </PrimaryButton>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <PrimaryButton
                    loading={loading}
                    disable={loading}
                    onClick={onSubmit}
                    className="smFullWidth"
                  >
                    Save
                  </PrimaryButton>
                </Grid>
              </>
            )}
          </Grid>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

const mapStateToProps = (state: any) => {
  return {
    loader: state.loader,
    user: state.user,
    dialog: state.dialog,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUser: (user: any) => dispatch({ type: "SET_LOGGIN_USER", user: user }),
    setPopup: (dialog: any) => dispatch({ type: "SET_POPUP", name: dialog }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadProfilePicture);
