import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import visibility from "../images/eye.svg";
import visibilityOff from "../images/eye-off.svg";
import InputBase from "@material-ui/core/InputBase";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "1px solid #ced4da",
    borderRadius: "10px",
    marginTop: theme.spacing(1),
    overflow: "hidden",
    "&.Mui-focused": {
      boxShadow: "none",
      borderColor: "#12182B",
    },
  },
  input: {
    border: "none",
  },
}));

export default function PasswordInput(props: any) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <InputBase
      {...props}
      type={showPassword ? "text" : "password"}
      classes={{
        root: classes.paper,
        input: classes.input,
      }}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => setShowPassword(!showPassword)}
            edge="end"
          >
            {showPassword ? (
              <img src={visibility} alt="visibility" />
            ) : (
              <img src={visibilityOff} alt="visibility off" />
            )}
          </IconButton>
        </InputAdornment>
      }
    />
  );
}
