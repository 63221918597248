const PlanList = [
  {
    duration: 0, // monthly plan
    size: "5 TB",
    planDetail: ["5 GB free downloads", "$0.15 per additional GB download"],
    price: 8.99,
    downloadPrice: 0.15,
    free_download: (1024 * 1024 * 1024) * 5,
    sizeInByte: (1024 * 1024 * 1024 * 1024) * 5,
  },
  {
    duration: 0, // monthly plan
    size: "10 TB",
    planDetail: ["20 GB free downloads", "$0.14 per additional GB download"],
    price: 16.99,
    downloadPrice: 0.14,
    free_download: (1024 * 1024 * 1024) * 20,
    sizeInByte: (1024 * 1024 * 1024 * 1024) * 10,
  },
  {
    duration: 0, // monthly plan
    size: "30 TB",
    planDetail: ["40 GB free downloads", "$0.13 per additional GB download"],
    price: 49.99,
    downloadPrice: 0.13,
    free_download: (1024 * 1024 * 1024) * 40,
    sizeInByte: (1024 * 1024 * 1024 * 1024) * 30,
  },
  {
    duration: 1, // yearly plan
    size: "5 TB",
    planDetail: ["5 GB free downloads", "$0.15 per additional GB download"],
    price: 89 / 12,
    downloadPrice: 0.15,
    free_download: (1024 * 1024 * 1024) * 5,
    sizeInByte: (1024 * 1024 * 1024 * 1024) * 5,
  },
  {
    duration: 1, // yearly plan
    size: "10 TB",
    planDetail: ["20 GB free downloads", "$0.14 per additional GB download"],
    price: 169 / 12,
    downloadPrice: 0.14,
    free_download: (1024 * 1024 * 1024) * 20,
    sizeInByte: (1024 * 1024 * 1024 * 1024) * 10,
  },
  {
    duration: 1, // yearly plan
    size: "30 TB",
    planDetail: ["40 GB free downloads", "$0.13 per additional GB download"],
    price: 499 / 12,
    downloadPrice: 0.13,
    free_download: (1024 * 1024 * 1024) * 40,
    sizeInByte: (1024 * 1024 * 1024 * 1024) * 30,
  },
];

export { PlanList };
