import React from "react";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import {
  Box,
  Checkbox,
  CssBaseline,
  Typography,
  Container,
  FormControlLabel,
  InputLabel,
  FormControl,
  Button,
  makeStyles,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useQueryParams } from "../Library/Hooks";
import googleIcon from "../images/google.svg";
import { Service, errorResponseHandler } from "../Services/Service";
import { validEmail } from "../Library/Validation";
import ErrorMessage from "../Component/ErrorMessage";
import PrimaryInput from "../Component/PrimaryInput";
import PasswordInput from "../Component/PasswordInput";
import PrimaryButton from "../Component/PrimaryButton";
import UnauthenticateContainer from "./Layout/UnauthenticateContainer";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  or: {
    textAlign: "center",
    borderBottom: "2px solid rgb(245 239 239)",
    lineHeight: "0.1em",
    margin: "25px 0",
    width: "100%",
    "& > span": {
      background: "#fff",
      padding: "0 10px",
    },
  },
  formControl: {
    width: "100%",
    margin: theme.spacing(0, 0, 2),
  },
  socialBtn: {
    borderRadius: "10px",
    fontSize: "16px",
    textTransform: "none",
    "& img": {
      marginRight: "10px",
    },
  },
}));

let auth2: any;
let email = "";

function getRedirectUrl() {
  let domain = new URL(document.location.href);
  return domain.protocol + "//" + domain.host;
}

function Login(props: any) {
  const classes = useStyles();
  const history = useHistory();
  const { redirect } = useQueryParams();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [staySignedIn, setStaySignedIn] = React.useState(false);
  const [pageState, setPageState] = React.useState("");
  const [otp, setOtp] = React.useState("");
  const [twoFAError, setTwoFAError] = React.useState({});
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    setLoading(true);
    email = data.email;
    Service.post("login", {
      email: data.email,
      password: data.password,
    })
      .then((response) => {
        setLoading(false);
        loginSuccess(response);
      })
      .catch((error) => {
        loginFail(error);
      });
  };

  const verifyOtp = (data: any) => {
    if (isValidOTP()) {
      setLoading(true);
      Service.post("twoFactor/login", {
        email: email,
        token: otp,
        type: "authenticator",
      })
        .then((response) => {
          loginSuccess(response);
        })
        .catch((error) => {
          loginFail(error);
        });
    }
  };

  const isValidOTP = () => {
    let type = "";
    if (otp.trim().length === 0) {
      type = "required";
    } else if (isNaN(parseInt(otp.trim()))) {
      type = "number";
    } else if (otp.trim().length !== 6) {
      type = "matchLengthNumber";
    }
    setTwoFAError({ type: type });
    return type.length === 0;
  };

  const loginSuccess = (response: any) => {
    if (response.data.login_status === 1) {
      if (redirect) {
        history.push({
          pathname: "verify-otp",
          search: `?email=${email}&redirect=${redirect}`,
        });
      } else {
        history.push({
          pathname: "verify-otp",
          search: `?email=${email}`,
        });
      }
      return;
    } else if (response.data.login_status === 2) {
      setLoading(false);
      setPageState("otp_page");
      return;
    }
    props.setLoggedinUser({
      stay_signed_in: staySignedIn ? 1 : 0,
      ...response.data.data,
    });
    enqueueSnackbar(response.data.message, {
      key: new Date().getTime() + Math.random(),
      variant: "success",
    });

    if (redirect) {
      history.push({
        pathname: redirect
      });
    } else {
      history.push({
        pathname: "/",
        search: `${props.location.search}`,
      });
    }
  };

  const loginFail = (error: any) => {
    setLoading(false);
    
    if (errorResponseHandler(error, enqueueSnackbar)) {
      if (error.response.status === 409) {
        if (error.response.data.error_code === "EMAIL_NOT_IN_WHITELIST") {
          history.push("/pre-launch-notification");
          return;
        }
      }
      enqueueSnackbar(error.response.data.message, {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      });
    }
  };

  const googleLogin = () => {
    auth2.signIn().then(
      (googleUser: any) => {
        Service.post("google/login", {
          access_token: googleUser.getAuthResponse().id_token,
          social_type: "google",
        })
          .then((response) => {
            loginSuccess(response);
          })
          .catch((error) => {
            loginFail(error);
          });
      },
      (error: any) => {
        console.log("user failed to sign in", error);
      }
    );
  };

  const googleSDK = () => {
    (window as any).googleSDKLoaded = () => {
      (window as any).gapi.load("auth2", () => {
        auth2 = (window as any).gapi.auth2.init({
          client_id:
            "584033038605-0eo50m5ctvv1fidajjnv12he78m1n63a.apps.googleusercontent.com",
          scope: "profile email",
          redirect_uri: getRedirectUrl() + "/login",
        });
      });
    };

    (function (d: any, s, id) {
      let js: any;
      let fjs: any = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://apis.google.com/js/api.js?onload=googleSDKLoaded";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "google-jssdk");
  };

  React.useEffect(() => {
    googleSDK();
  });

  const otpScreen = (
    <>
      <Typography>
        Enter the 6-digit code generated by your authenticator app.
      </Typography>

      <Box mt={3} mb={3}>
        <FormControl>
          <InputLabel shrink htmlFor="otp" className="form-label">
            6-digit Code
          </InputLabel>
          <PrimaryInput
            id="otp"
            inputProps={{
              maxLength: 6,
            }}
            onChange={(e) => setOtp(e.target.value)}
          />
          <ErrorMessage errors={twoFAError} label="6" />
        </FormControl>
      </Box>

      <PrimaryButton
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={loading}
        loading={loading}
        className={classes.submit}
        onClick={verifyOtp}
      >
        Sign In
      </PrimaryButton>
    </>
  );

  return (
    <UnauthenticateContainer>
      <Box p={2} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h4" align="left">
            <b>Sign in to your account</b>
          </Typography>
          <Box p={1} />

          {pageState === "otp_page" && otpScreen}
          {pageState === "" && (
            <>
              

              {/* <Box p={2} />
              <Button
                variant="outlined"
                className={classes.socialBtn}
                onClick={googleLogin}
              >
                <img src={googleIcon} alt="google login" /> Sign In with Google
              </Button>

              <p className={classes.or}>
                <span>OR</span>
              </p> */}

              <form
                className={classes.form}
                noValidate
                onSubmit={handleSubmit(onSubmit)}
              >
                <FormControl className={classes.formControl}>
                  <InputLabel shrink htmlFor="email" className="form-label">
                    Email
                  </InputLabel>
                  <PrimaryInput
                    placeholder="Email"
                    id="email"
                    {...register("email", {
                      required: true,
                      validate: {
                        email: (value: string) => validEmail(value),
                      },
                    })}
                  />
                  <ErrorMessage errors={errors.email} />
                </FormControl>

                <FormControl className={classes.formControl}>
                  <Box justifyContent={"space-between"} display={"flex"}>
                    <InputLabel shrink htmlFor="email" className="form-label">
                      Password
                    </InputLabel>

                    <Link to={"forgot-password"} className="primary">
                      <InputLabel
                        shrink
                        htmlFor="forgot"
                        className="form-label"
                      >
                        Forgot password?
                      </InputLabel>
                    </Link>
                  </Box>

                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      maxLength: 100,
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <PasswordInput
                        placeholder="Password"
                        id="password"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  <ErrorMessage errors={errors.password} />
                </FormControl>

                <FormControlLabel
                  control={
                    <Checkbox
                      value="remember"
                      color="primary"
                      onClick={() => setStaySignedIn(!staySignedIn)}
                    />
                  }
                  label="Stay signed in"
                />
                <PrimaryButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  loading={loading}
                  className={classes.submit}
                >
                  Sign In
                </PrimaryButton>
              </form>
            </>
          )}
        </div>
      </Container>
    </UnauthenticateContainer>
  );
}

const mapStateToProps = (state: any) => {
  return {
    isLoggedIn: state.isLoggedIn,
    experience: state.experience,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLoggedinUser: (user: any) =>
      dispatch({ type: "SET_LOGGIN_USER", user: user }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
