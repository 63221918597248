import React from "react";
import AppBar from "@material-ui/core/AppBar";
import { Toolbar, CssBaseline } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import logoIcon from "../../images/logo.svg";

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: "#12182B",
    boxShadow: "none",
  },
  logo: {
    textDecoration: "none",
    display: "flex",
  },
  toolbarWraper: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    backgroundColor: "#12182B",
  }
}));

export default function FullHeader(props: any) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbarWraper}>
          <Link to={`/`} className={classes.logo}>
            <img src={logoIcon} alt="logo" className="brand" />
          </Link>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
