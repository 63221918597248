import { connect } from "react-redux";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  topNotification: {
    padding: "5px",
    textAlign: "center",
    background: "#D94E45",
    width: "100%",
    minHeight: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function HeaderTopNotification(props: any) {
  const classes = useStyles();

  if (props.user && props.user.status === "payment_failed") {
    return (
      <div className={classes.topNotification + " top-notification"}>
        <Typography variant="body2" className="f-12">
          There was an issue processing your payment!&nbsp;
          <span
            className="make-link"
            onClick={() => props.setPopup("payment_method")}
          >
            Update your payment method
          </span>
        </Typography>
      </div>
    );
  }
  return <div></div>;
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPopup: (name: string) => dispatch({ type: "SET_POPUP", name: name }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderTopNotification);
