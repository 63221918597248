import * as React from "react";
import PaginateComponent from "../Component/PaginateComponent";
import { Chip } from "@material-ui/core";
import { formateDate } from "../Helper/Common";

function StoredPage(props: any) {
  const headCells = [
    {
      id: "whitelist_id",
      numeric: false,
      disablePadding: true,
      align: "left",
      label: "ID",
      beautify: (row: any) => {
        return row.id;
      },
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      align: "left",
      label: "Email",
      beautify: (row: any) => {
        return row.email;
      },
    },
  ];

  return (
    <PaginateComponent
      apiPrefix="whitelist"
      component="whitelist"
      headCells={headCells}
      title={"Whitelist"}
    />
  );
}

export default StoredPage;
