import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

const CustomButton = withStyles({
  contained: {
    fontSize: 16,
    borderRadius: "14px",
    lineHeight: 1,
    color: "#fff",
    border: "solid 2px #12182B",
    backgroundColor: "#12182B",
    textTransform: "none",
    textAlign: "center",
    height: "48px",
    "&:hover": {
      backgroundColor: "#12182B",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#12182B",
    },
    "&:disabled": {
      cursor: "not-allowed",
      border: "solid 1.5px #EAEAEA",
    },
  },
  outlined: {
    height: "48px",
    fontSize: 16,
    borderRadius: "14px",
    lineHeight: 1,
    border: "solid 2px #12182B",
    textTransform: "none",
    color: "#12182B",
    textAlign: "center",
    "&:hover": {
      boxShadow: "none",
      border: "solid 2px #12182B",
      color: "#12182B",
    },
    "&:active": {
      boxShadow: "none",
      border: "solid 2px #12182B",
      color: "#12182B",
    },
    "&:focus": {
      border: "solid 2px #12182B",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
    "&:disabled": {
      border: "solid 1.5px #EAEAEA",
      cursor: "not-allowed",
    },
  },
})(Button);

const PrimaryButton = (props: any) => {
  return (
    <Box
      className={props.loading ? "loading-button" : ""}
      sx={{ position: "relative" }}
      ml={props.ml || 0}
      style={{ display: "flex", flexDirection: "inherit" }}
    >
      <CustomButton
        variant="contained"
        color="primary"
        disabled={props.loading}
        {...props}
        loading={props.loading ? props.loading : undefined}
      >
        {props.children}
      </CustomButton>

      {props.loading && (
        <CircularProgress
          size={24}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </Box>
  );
};
export default PrimaryButton;
