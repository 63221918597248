import * as React from "react";
import PaginateComponent from "../Component/PaginateComponent";
import { Chip } from "@material-ui/core";
import { formateDate } from "../Helper/Common";

function StoredPage(props: any) {
  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      align: "left",
      label: "Name",
      beautify: (row: any) => {
        return row.first_name + " " + row.last_name;
      },
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      align: "left",
      label: "Email",
      beautify: (row: any) => {
        return row.email;
      },
    },
    {
      id: "is_subscribed",
      numeric: false,
      disablePadding: false,
      align: "center",
      label: "Subscribed",
      beautify: (row: any) => {
        if (row.is_subscribe === 1) {
          return <Chip label="Subscribed" size="small" />;
        }
        return <Chip label="Not Subscribe" size="small" color="secondary" />;
      },
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      align: "center",
      label: "Status",
      beautify: (row: any) => {
        if (row.status === "active") {
          return <Chip label="Active" size="small" color="primary" />;
        } else if (row.status === "inactive") {
          return <Chip label="Inactive" size="small" color="secondary" />;
        }
        return <Chip label="Payment failed" size="small" color="secondary" />;
      },
    },
    {
      id: "created_at",
      numeric: false,
      disablePadding: false,
      align: "right",
      label: "Created At",
      beautify: (row: any) => {
        return formateDate(new Date(row.created_at));
      },
    },
  ];

  return (
    <PaginateComponent
      apiPrefix="users"
      component="User"
      headCells={headCells}
      title={"Users"}
    />
  );
}

export default StoredPage;
