import { Service } from "../Services/Service";

export const getMe = (fileObject) => {
  return (dispatch) => {
    Service.get('me')
      .then((response) => {
        dispatch(setUserData(response.data.data));
      })
      .catch((error) => {});
  };
};

export const getSubscriptionData = (fileObject) => {
  return (dispatch) => {
    Service.get(`billing/breakDown`)
      .then((response) => {
        dispatch(setSubscriptionData(response.data.subscription));
      })
      .catch((error) => {});
  };
};

export const setSubscriptionData = (subscription) => {
  return {
    type: "UPDATE_SUBSCRIPTION",
    subscription,
  };
};


export const setUserData = (user) => {
  return {
    type: "SET_USER",
    user,
  };
};
