import React from 'react';

export default function ErrorMessage(props: any) {
    if (props.errors) {
        return (
            <div className="form-error">
                {(() => {
                    switch (props.errors.type) {
                        case 'required':
                            return 'This field is required'
                        case 'email':
                            return 'This field must have valid email'
                        case 'email_already_exists':
                            return 'Looks like email already exists'
                        case 'number':
                            return 'This field must contain number'
                        case 'match':
                            return `This field must match with ${props.label}`
                        case 'matchLengthNumber':
                            return `This field must contain at least ${props.label} digits!`
                        case 'expiryDate':
                            return `This field must contain valid value`
                    }
                })()}
            </div>
        );
    }

    return (
        <div></div>
    );
  
}

