import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import HomePage from "./Pages/HomePage";
import ForgotPassword from "./Pages/ForgotPassword";
import ForgotPasswordSuccess from "./Pages/ForgotPasswordSuccess";
import AvailableFilePage from "./Pages/AvailableFilePage";
import RequestedPage from "./Pages/RequestedPage";
import WhitelistPage from "./Pages/WhitelistPage";
import StoredPage from "./Pages/StoredPage";
import ProfilePage from "./Pages/ProfilePage";
import SecurityPage from "./Pages/SecurityPage";
import { StorageKey } from "./Config/StorageKey";
import { connect } from "react-redux";
import { SnackbarProvider } from "notistack";
import StorageMinimumTime from "./Pages/StorageMinimumTime";
import DeleteAccountPage from "./Pages/DeleteAccountPage";
import PreLaunchPage from "./Pages/PreLaunchPage";
import {
  ReasonsPage,
  FeedbackPage,
  OtpVerification,
  ResetPassword,
} from "./Pages";
import FeedbackSuccessPage from "./Pages/FeedbackSuccessPage";
import IdealTimeHandler from "./Component/IdealTimeHandler";
import { IconButton, makeStyles } from "@material-ui/core";
import closeIcon from "./images/close_white.svg";
import errorIcon from "./images/error_outline.svg";
import infoIcon from "./images/info_outline.svg";
import GlobalContext from "./Context/GlobalContext";
import AuthenticateContainer from "./Pages/Layout/AuthenticateContainer";
import { ErrorBoundary } from "./Component/ErrorBoundry";

const useStyles = makeStyles((theme: any) => ({
  containerRoot: {
    zIndex: 2000,
  },
  success: {
    backgroundColor: "#12182B",
    borderRadius: "12px",
    alignItems: "start",
  },
  error: {
    backgroundColor: "#12182B",
    color: "#D94E45",
    borderRadius: "12px",
    alignItems: "start",
  },
  notifyBtn: {
    padding: "5px",
    alignItems: "start",
    display: "flex",
  },
}));

function App(props: any) {
  const classes = useStyles();
  const [count, setCount] = React.useState(0);
  const [isLoggedIn, setIsLoggedIn] = React.useState("WAITING");

  React.useEffect(() => {
    const user = localStorage.getItem(StorageKey.USER);
    if (user) {
      setIsLoggedIn("LOGGED_IN");
    } else {
      setIsLoggedIn("LOGGED_OUT");
    }
  }, []);

  const notistackRef = React.createRef<SnackbarProvider>();
  const onClickDismiss = (key: any) => {
    // @ts-ignore
    notistackRef.current.closeSnackbar(key);
  };

  if (isLoggedIn === "WAITING") {
    return <div></div>;
  }

  return (
    <SnackbarProvider
      ref={notistackRef}
      hideIconVariant={false}
      maxSnack={3}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      action={(key) => (
        <IconButton
          classes={{ root: classes.notifyBtn }}
          onClick={() => onClickDismiss(key)}
        >
          <img src={closeIcon} alt="close" />
        </IconButton>
      )}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        containerRoot: classes.containerRoot,
      }}
      iconVariant={{
        error: <img src={errorIcon} alt="errorIcon" className="notify-icon" />,
        success: <img src={infoIcon} alt="infoIcon" className="notify-icon" />,
      }}
    >
      <GlobalContext.Provider value={{ count, toggle: setCount }}>
        <IdealTimeHandler />
        <ErrorBoundary>
          <Router>
            <Switch>
              <Route exact path={"/login"} component={Login} />
              <Route exact path={"/verify-otp"} component={OtpVerification} />
              <Route exact path={"/register"} component={Register} />
              <Route
                exact
                path={"/forgot-password"}
                component={ForgotPassword}
              />
              <Route
                exact
                path={"/forgot-password-success"}
                component={ForgotPasswordSuccess}
              />
              <Route
                exact
                path={"/reset-password/:token"}
                component={ResetPassword}
              />
              <Route
                exact
                path={"/storage-minimum-time"}
                component={StorageMinimumTime}
              />
              <Route
                exact
                path={"/delete-account"}
                component={DeleteAccountPage}
              />
              <Route
                exact
                path={"/pre-launch-notification"}
                component={PreLaunchPage}
              />
              <Route exact path={"/feedback"} component={FeedbackPage} />
              <Route
                exact
                path={"/feedback-success"}
                component={FeedbackSuccessPage}
              />
              <PrivateRoute isLoggedIn={isLoggedIn} {...props}>
                <Switch>
                  <Route exact path={"/security"}>
                    <AuthenticateContainer>
                      <SecurityPage />
                    </AuthenticateContainer>
                  </Route>
                  <Route exact path={"/profile"}>
                    <AuthenticateContainer>
                      <ProfilePage />
                    </AuthenticateContainer>
                  </Route>
                  <AuthenticateContainer sidebar="home">
                    <Route exact path={"/"} component={HomePage} />
                    <Route
                      exact
                      path={"/available"}
                      component={AvailableFilePage}
                    />
                    <Route
                      exact
                      path={"/whitelist"}
                      component={WhitelistPage}
                    />
                    <Route exact path={"/transaction"} component={StoredPage} />
                    <Route exact path={"/feedbacks"} component={FeedbackPage} />
                    <Route exact path={"/reasons"} component={ReasonsPage} />
                    <Route
                      exact
                      path={"/requested/:id?"}
                      component={RequestedPage}
                    />
                  </AuthenticateContainer>
                </Switch>
              </PrivateRoute>
            </Switch>
          </Router>
        </ErrorBoundary>
      </GlobalContext.Provider>
    </SnackbarProvider>
  );
}

function PrivateRoute(props: any) {
  const url = new URL(document.location.href);
  return (
    <Route
      {...props.rest}
      render={({ location }) =>
        props.isLoggedIn ? (
          props.children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              search: `${location.search}&redirect=${url.pathname}`,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const mapStateToProps = (state: any) => {
  return {
    isLoggedIn: state.isLoggedIn,
    flashMessages: state.flashMessages,
  };
};

export default connect(mapStateToProps, null)(App);
