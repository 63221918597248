import React, { useRef } from "react";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { useForm, Controller } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  Box,
  Grid,
  Button,
  Container,
  Typography,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import googleIcon from "../images/google.svg";
import { makeStyles } from "@material-ui/core/styles";
import { Service, errorResponseHandler } from "../Services/Service";
import { validEmail } from "../Library/Validation";
import PrimaryInput from "../Component/PrimaryInput";
import ErrorMessage from "../Component/ErrorMessage";
import PasswordInput from "../Component/PasswordInput";
import PrimaryButton from "../Component/PrimaryButton";
import UnauthenticateContainer from "./Layout/UnauthenticateContainer";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(0, 0, 2),
  },
  or: {
    textAlign: "center",
    borderBottom: "2px solid rgb(245 239 239)",
    lineHeight: "0.1em",
    margin: "25px 0",
    width: "100%",
    "& > span": {
      background: "#fff",
      padding: "0 10px",
    },
  },
  formControl: {
    width: "100%",
    margin: theme.spacing(0, 0, 2),
  },
  socialBtn: {
    borderRadius: "10px",
    fontSize: "16px",
    textTransform: "none",
    "& img": {
      marginRight: "10px",
    },
  },
}));

let auth2: any;

function getRedirectUrl() {
  let domain = new URL(document.location.href);
  return domain.protocol + "//" + domain.host;
}

function Register(props: any) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const password = useRef({});
  password.current = watch("password", "");
  const [loading, setLoading] = React.useState(false);
  const [emailError, setEmailError] = React.useState({ type: "" });

  const onSubmit = (data: any) => {
    setLoading(true);
    Service.post("signUp", {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      password: data.password,
    })
      .then((response) => {
        setLoading(false);
        registerSuccess(response);
      })
      .catch((error) => {
        registerFail(error);
      });
  };

  const registerSuccess = (response: any) => {
    props.setLoggedinUser(response.data.data);
    enqueueSnackbar(response.data.message, {
      key: new Date().getTime() + Math.random(),
      variant: "success",
    });

    history.push("/");
  };

  const registerFail = (error: any) => {
    setLoading(false);
    if (errorResponseHandler(error, enqueueSnackbar)) {
      if (error.response.status === 409) {
        if (error.response.data.error_code === "EMAIL_NOT_IN_WHITELIST") {
          history.push("/pre-launch-notification");
          return;
        }
        setEmailError({
          type: "email_already_exists",
        });
        return;
      }
      enqueueSnackbar(error.response.data.message, {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      });
    }
  };

  const googleLogin = () => {
    auth2.signIn().then(
      (googleUser: any) => {
        Service.post("google/login", {
          access_token: googleUser.getAuthResponse().id_token,
          social_type: "google",
        })
          .then((response) => {
            registerSuccess(response);
          })
          .catch((error) => {
            registerFail(error);
          });
      },
      (error: any) => {
        console.log("user failed to sign in", error);
      }
    );
  };

  const googleSDK = () => {
    (window as any).googleSDKLoaded = () => {
      (window as any).gapi.load("auth2", () => {
        auth2 = (window as any).gapi.auth2.init({
          client_id:
            "584033038605-0eo50m5ctvv1fidajjnv12he78m1n63a.apps.googleusercontent.com",
          scope: "profile email",
          redirect_uri: getRedirectUrl() + "/login",
        });
      });
    };

    (function (d: any, s, id) {
      let js: any;
      let fjs: any = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://apis.google.com/js/api.js?onload=googleSDKLoaded";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "google-jssdk");
  };

  React.useEffect(() => {
    googleSDK();
  });

  return (
    <UnauthenticateContainer>
      <Box p={2} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h4" align="left">
            <b>Create a new account</b>
          </Typography>
          <Box p={1} />
          <Typography variant="subtitle1" align="left">
            Already have an account?&nbsp;
            <Link to={"login"} className="primary">
              Sign in
            </Link>
          </Typography>
          <Box p={2} />
          <Button
            variant="outlined"
            className={classes.socialBtn}
            onClick={googleLogin}
          >
            <img src={googleIcon} alt="google login" /> Sign Up with Google
          </Button>

          <p className={classes.or}>
            <span>OR</span>
          </p>

          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <InputLabel
                    shrink
                    htmlFor="firstname"
                    className="form-label"
                    required={true}
                  >
                    First Name
                  </InputLabel>
                  <PrimaryInput
                    placeholder="First Name"
                    id="firstname"
                    {...register("firstName", { required: true })}
                  />
                  <ErrorMessage errors={errors.firstName} />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <InputLabel
                    shrink
                    htmlFor="lastName"
                    className="form-label"
                    required={true}
                  >
                    Last Name
                  </InputLabel>
                  <PrimaryInput
                    placeholder="Last Name"
                    id="lastName"
                    {...register("lastName", { required: true })}
                  />
                  <ErrorMessage errors={errors.lastName} />
                </FormControl>
              </Grid>
            </Grid>

            <FormControl className={classes.formControl}>
              <InputLabel
                shrink
                htmlFor="email"
                className="form-label"
                required={true}
              >
                Email
              </InputLabel>
              <PrimaryInput
                placeholder="Email"
                id="email"
                {...register("email", {
                  required: true,
                  validate: {
                    email: (value) => validEmail(value),
                  },
                })}
                onFocus={() => setEmailError({ type: "" })}
              />
              {!errors.email && <ErrorMessage errors={emailError} />}
              <ErrorMessage errors={errors.email} />
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel
                shrink
                htmlFor="password"
                className="form-label"
                required={true}
              >
                Password
              </InputLabel>
              <Controller
                name="password"
                control={control}
                rules={{
                  maxLength: 100,
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <PasswordInput
                    placeholder="Password"
                    id="password"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <ErrorMessage errors={errors.password} />
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel shrink className="form-label" required={true}>
                Password Confirmation
              </InputLabel>
              <Controller
                name="confirm_password"
                control={control}
                rules={{
                  maxLength: 100,
                  required: true,
                  validate: {
                    match: (value: string) => value === password.current,
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <PasswordInput
                    placeholder="Password Confirmation"
                    id="password_confirmation"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <ErrorMessage errors={errors.confirm_password} label="password" />
            </FormControl>

            {/* <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  color="primary"
                  {...register("termsAndCondition", { required: true })}
                />
              }
              label={
                <Typography variant="subtitle2">
                  <b>
                    I have read and agree to the{" "}
                    <Link
                      to={"terms-of-conditions"}
                      target="_blank"
                      className="primary"
                    >
                      Terms of conditions
                    </Link>
                  </b>
                </Typography>
              }
            />
            <ErrorMessage errors={errors.termsAndCondition} /> */}
            <PrimaryButton
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              loading={loading}
              className={classes.submit}
            >
              Continue
            </PrimaryButton>

            <Typography variant="body2" className="dark-grey">
              By creating an account, you agree to our&nbsp;
              <a
                rel="noreferrer"
                href="https://alpii.com/privacy"
                target="_blank"
                className="primary"
              >
                privacy
              </a>
              &nbsp;&&nbsp;
              <a
                rel="noreferrer"
                href="https://alpii.com/terms"
                target="_blank"
                className="primary"
              >
                terms
              </a>
            </Typography>
          </form>
        </div>
      </Container>
    </UnauthenticateContainer>
  );
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLoggedinUser: (user: any) =>
      dispatch({ type: "SET_LOGGIN_USER", user: user }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
