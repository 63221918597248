import React from "react";
import {
  Box,
  Typography,
  Grid,
} from "@material-ui/core";
import AuthenticateContainer from "./Layout/AuthenticateContainer";

export default function FeedbackSuccessPage(props: any) {

  return (
    <AuthenticateContainer sidebar="none">
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={10} sm={6} md={5} xl={6} lg={5}>
          <Typography variant="h6" gutterBottom>
            <b>Feedback</b>
          </Typography>
          <Box mt={3} />
          <Typography gutterBottom>
          Thanks for your feedback! 🙏 We look at all feedback and will reach out to you if we have any questions.
          </Typography>
        </Grid>
      </Grid>
    </AuthenticateContainer>
  );
}
