import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Link,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { connect } from "react-redux";
import PrimaryButton from "../Component/PrimaryButton";
import * as Icon from "../Icon/index";

const AccountUpgraded = (props: any) => {
  const handleClose = () => {
    props.setPopup("");
  };

  return (
    <Dialog
      id="upgrade-account"
      scroll="body"
      open={props.dialog === "UpgradeAccount"}
      onClose={handleClose}
      PaperProps={{
        style: {
          background: "#12182B",
          color: "#fff",
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h3" className="sm-up">
              Upgrade
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={handleClose}>
              <Icon.Cancel className="white-icon" />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item xs={12}>
            <Box
              justifyContent="center"
              display="flex"
              flexDirection="column"
              height="100%"
            >
              <Box className="sm-down" mb={5}>
                <Typography variant="h1">
                  Your account has been upgraded! 🎉
                </Typography>
              </Box>
              <Typography variant="h3">
                Thanks for upgrading, you can now enjoy all that Alpii has to
                offer including:
              </Typography>

              <Box mt={3} />
              <List dense={true}>
                {[
                  "60% cheaper than traditional cloud storage*",
                  "$1.99/TB storage",
                  "100 GB included retrievals per storage TB",
                  "Additional retrievals $0.99 per 100 GB",
                ].map((item, index) => (
                  <ListItem key={`aup-${index}`} disableGutters alignItems="flex-start">
                    <ListItemIcon>
                      <Icon.Check className="green-icon feature" />
                    </ListItemIcon>
                    <ListItemText
                      primary={<Typography variant="h3">{item}</Typography>}
                    />
                  </ListItem>
                ))}
              </List>
              <Box display="flex" alignItems="center" mt={5} mb={5}>
                <Link href="https://alpii.com/pricing" target="_blank">
                  <Typography variant="h4" className="color-white make-link">
                    Learn more about pricing
                  </Typography>
                </Link>
              </Box>
              <Box display="flex" alignItems="center">
                <Typography variant="body2" className="text-grey">
                  *Based on Google One Premium plan pricing as of February 3,
                  2022.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm="auto">
            <PrimaryButton
              variant="contained"
              type="submit"
              className="smFullWidth type-2"
              onClick={handleClose}
            >
              Done
            </PrimaryButton>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state: any) => {
  return {
    dialog: state.dialog,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPopup: (name: string) => dispatch({ type: "SET_POPUP", name: name }),
    setUser: (user: any) => dispatch({ type: "SET_LOGGIN_USER", user: user }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountUpgraded);
