import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Service } from "../Services/Service";
import { FolderRow, FileRow, RowHeader } from "../Component/FolderRow";
import * as Icon from "../Icon/index";
import {
  ContextMenu,
  ContextMenuItem,
  ContextMenuDivider,
} from "../Component/ContextMenu";
import { InlinePageLoader } from "../Component/Loaders";
import { PageBreadcrumb } from "../Component/PageBreadcrumb";
import { initiateDeleteResource } from "../actions/Resource";

function AvailableFilePage(props: any) {
  const urlParams: {
    id: string;
  } = useParams();
  const [selectedItem, setSelectedItem] = React.useState<{
    id: string;
    type: string;
  } | null>(null);
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (
    event: React.MouseEvent,
    resourceObject: { id: string; type: string }
  ) => {
    event.preventDefault();
    setSelectedItem(resourceObject);
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleDownload = () => {
    handleClose();
    window.open(
      `${process.env.REACT_APP_API_URL}/glaceir/outPutJob/${selectedItem?.id}`,
      "_blank"
    );
  };

  const handleDelete = () => {
    handleClose();
    props.initiateDeleteResource(selectedItem);
  };

  const getPageData = (id: any) => {
    props.setLoader(true);
    Service.get(`files/available`)
      .then((response) => {
        props.setLoader(false);
        console.log(response);
        props.onFolder(response.data.data);
        props.setFiles(response.data.files);
      })
      .catch((error) => {
        props.setLoader(false);
        props.onFolder([]);
      });
  };

  const getDefaultData = () => {
    props.onFolder([]);
    props.setFiles([]);
    let id = urlParams.id;
    if (!id) {
      id = props.defaultFolder.id;
    }
    getPageData(id);
  };

  React.useEffect(() => {
    getDefaultData();
    //eslint-disable-next-line
  }, []);

  const rowItem = (value: any, index: number) => {
    return (
      <FolderRow
        value={value}
        page="available"
        key={`folder-${index}`}
        urlPrefix="available"
        disableNavigation={true}
        handleContextMenu={handleContextMenu}
      />
    );
  };

  const processDownload = (value: any, index: number) => {
    Service.post(`glaceir/initiateJob`, {
      file_id: value.id,
    })
      .then((response) => {
        getDefaultData();
      })
      .catch((error) => {});
  };

  const rowFile = (value: any, index: number) => {
    return (
      <div key={`file-${index}`}>
        <FileRow
          value={value}
          page="available"
          handleContextMenu={handleContextMenu}
          setFileDetail={() => props.setFileDetail(value)}
          processDownload={() => processDownload(value, index)}
        />
      </div>
    );
  };

  return (
    <>
      <PageBreadcrumb start="Available" urlPrefix="available" />
      <div className="folder-or-file-list">
        {props.loader && <InlinePageLoader />}

        {!props.loader && props.folders.length + props.files.length < 1 ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ height: "calc(100vh - 200px)" }}
          >
            <Typography variant="h6" gutterBottom>
              <Icon.Available
                className="secondry-icon"
                width="200"
                height="200"
              />
            </Typography>
            <Typography variant="h2" className="dark-grey" gutterBottom>
              No available files
            </Typography>
            <Typography
              variant="body2"
              className="dark-grey"
              align="center"
              gutterBottom
            >
              Files will appear here when they’re <br /> ready for download.
            </Typography>
          </Grid>
        ) : (
          !props.loader && <RowHeader status="Available for" />
        )}
        {props.folders.map((value: any, index: number) =>
          rowItem(value, index)
        )}
        {props.files.map((value: any, index: number) => rowFile(value, index))}
      </div>
      <ContextMenu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <ContextMenuItem onClick={handleDownload}>
          <Icon.Download /> &nbsp; Download
        </ContextMenuItem>
        <ContextMenuDivider />
        <ContextMenuItem onClick={handleDelete}>
          <Icon.Trash /> &nbsp; Trash
        </ContextMenuItem>
      </ContextMenu>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    folders: state.folders,
    files: state.files,
    defaultFolder: state.defaultFolder,
    loader: state.loader,
    uploadFile: state.uploadFile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onFolder: (folders: any) =>
      dispatch({ type: "SET_FOLDER", folders: folders }),
    setFiles: (files: any) => dispatch({ type: "SET_FILES", files: files }),
    setLoader: (loader: any) =>
      dispatch({ type: "SET_LOADER", loader: loader }),
    setFileDetail: (fileDetail: any) =>
      dispatch({ type: "SET_FILE_DETAIL", fileDetail: fileDetail }),
    initiateDeleteResource: (selectedItem: any) =>
      dispatch(initiateDeleteResource(selectedItem)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AvailableFilePage);
