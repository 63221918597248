import * as React from "react";
import { connect } from "react-redux";
import { Chip } from "@material-ui/core";
import PaginateComponent from "../Component/PaginateComponent";
import FloatingButton from "../Component/FloatingButton";
import { CreateReason } from "../Popup";
import { formateDate } from "../Helper/Common";

function ReasonsPage(props: any) {
  const [triggerRefresh, setTriggerRefresh] = React.useState(false);
  const headCells = [
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      align: "left",
      label: "Title",
      beautify: (row: any) => {
        return row.reason;
      },
    },
    {
      id: "type",
      numeric: false,
      disablePadding: true,
      align: "center",
      label: "For",
      beautify: (row: any) => {
        if (row.type === "delete") {
          return <Chip label="Delete" size="small" />;
        }
        return <Chip label="Feedback" size="small" color="secondary" />;
      },
    },
    {
      id: "created_at",
      numeric: false,
      disablePadding: false,
      align: "right",
      label: "Created at",
      beautify: (row: any) => {
        return formateDate(new Date(row.created_at));
      },
    },
  ];

  return (
    <>
      <FloatingButton onClick={() => props.setPopup("CREATE_REASON")} />
      <PaginateComponent
        enableSerialNumber={true}
        triggerRefresh={triggerRefresh}
        apiPrefix="reasons"
        component="feedback"
        headCells={headCells}
        title={"Reasons"}
      />
      <CreateReason onSaved={() => setTriggerRefresh(!triggerRefresh)} />
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPopup: (name: string) => dispatch({ type: "SET_POPUP", name: name }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReasonsPage);
