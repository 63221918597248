import { createTheme } from "@material-ui/core/styles";

const themeLight = createTheme({
  typography: {
    fontFamily: `'Poppins', sans-serif`,
    h1: {
      fontSize: "28px",
      fontWeight: 600,
    },
    h2: {
      fontSize: "24px",
      fontWeight: 600,
    },
    h3: {
      fontSize: "20px",
      fontWeight: 500,
    },
    h4: {
      fontSize: "18px",
      fontWeight: 500,
    },
    h5: {
      fontSize: "16px",
      fontWeight: 500,
    },
    h6: {
      fontSize: "16px",
      fontWeight: 400,
    },
    body2: {
      fontSize: "14px",
      fontWeight: 400,
    },
  },
  palette: {
    background: {
      default: "#fffff",
    },
    action: {
      disabledBackground: "#ffffff",
      disabled: "#000",
    },
    primary: {
      main: "#000",
    },
    text: {
      primary: "#12182b",
      secondary: "#12182b",
      disabled: "#12182b",
      hint: "#12182b",
    },
  },
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: "15px",
        padding: "10px",
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: "13px",
        color: "#12182B",
        fontWeight: 400,
      },
      asterisk: {
        color: "#D94E45",
      },
      colorSecondary: {
        color: "#fff",
      },
    },
    MuiLinearProgress: {
      root: {
        minHeight: "6px",
      },
      colorSecondary: {
        backgroundColor: "#EAEAEA",
        borderRadius: "15px",
      },
      barColorSecondary: {
        backgroundColor: "#12182B",
      },
    },
    MuiButton: {
      outlined: {
        padding: "12px 40px",
      },
      contained: {
        boxShadow: "none",
      },
      root: {
        fontSize: 16,
        padding: "12px 40px",
        borderRadius: "14px",
        lineHeight: 1,
        textTransform: "none",
        height: "48px",
      },
      containedSecondary: {
        backgroundColor: "#67D786",
        "&:hover": {
          backgroundColor: "#67D786",
        },
      },
    },
    MuiInputBase: {
      input: {
        fontWeight: 400,
        fontSize: "14px",
        padding: "12px",
        border: "1px solid #ced4da",
        height: "auto",
      },
      colorSecondary: {
        borderRadius: 10,
        overflow: "hidden",
        border: "1px solid #2c3245",
        color: "#ffffff",
        "&$focused": {
          border: "1px solid #3EE5DD",
        },
      },
      root: {
        marginBottom: "16px"
      }
    },
    MuiSelect: {
      root: {
        padding: "12px",
      },
    },
    MuiRadio: {
      root: {
        color: "#12182B",
      },
      colorSecondary: {
        "&$checked": {
          color: "#12182B",
        },
      },
    },
    MuiTypography: {
      colorError: {
        color: "#D94E45",
      },
    },
    MuiAvatar: {
      colorDefault: {
        color: "#ffffff",
      },
    },
    MuiTab: {
      textColorInherit: {
        color: "#3EE5DD",
      },
      root: {
        border: "solid 1px #3EE5DD",
        textTransform: "none",
        "&$selected": {
          backgroundColor: "#3EE5DD",
        },
      },
    },
  },
  zIndex: {
    modal: 1500,
    appBar: 1410,
  },
});

export default themeLight;
