import { connect } from "react-redux";
import { Service } from "../Services/Service";
import * as Icon from "../Icon/index";

function RequestDownload(props: any) {
  const requestDownload = () => {
    if (props.disable !== true) {
      props.updateFileStatus(props.id);
      Service.post(`glaceir/initiateJob`, {
        file_id: props.id,
      }).then(() => {});
    }
  };

  return (
    <div className="action-request" onClick={() => requestDownload()}>
      <Icon.Cloud />
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateFileStatus: (id: any) =>
      dispatch({ type: "SET_FILE_STATUS", id: id, status: 1 }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestDownload);
