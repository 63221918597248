import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

const CustomButton = withStyles({
  contained: {
    fontSize: 16,
    height: "48px",
    padding: "12px 40px",
    borderRadius: "14px",
    lineHeight: 1.5,
    backgroundColor: "#D94E45",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#D94E45",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#D94E45",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
    "&:disabled": {
      cursor: "not-allowed",
    },
  },
  outlined: {
    fontSize: 16,
    height: "48px",
    padding: "8px 12px",
    borderRadius: "10px",
    lineHeight: 1.5,
    border: "solid 2px #D94E45",
    textTransform: "none",
    color: "#D94E45",
    "&:hover": {
      backgroundColor: "#D94E45",
      boxShadow: "none",
      border: "solid 2px #D94E45",
      color: "#ffffff",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#D94E45",
      border: "solid 2px #D94E45",
      color: "#ffffff",
    },
    "&:focus": {
      border: "solid 2px #D94E45",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
    "&:disabled": {
      border: "solid 2px #EAEAEA",
      cursor: "not-allowed",
    },
  },
})(Button);

const DangerButton = (props: any) => {
  return (
    <Box sx={{ position: "relative" }}>
      <CustomButton
        variant="contained"
        type="submit"
        color="primary"
        disabled={props.loading}
        {...props}
        loading={props.loading ? props.loading : undefined}
      >
        {props.children}
      </CustomButton>

      {props.loading && (
        <CircularProgress
          size={24}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </Box>
  );
};
export default DangerButton;
