import React from "react";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import {
  Toolbar,
  Avatar,
  Grid,
  Box,
  Typography,
  CssBaseline,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { Link, useHistory } from "react-router-dom";
import hamburgerIcon from "../../images/hamburger.svg";
import leftArrowIcon from "../../images/white/arrow-left.svg";
import { Divider, ListItemIcon, Menu, MenuItem } from "@material-ui/core";
import settingIcon from "../../images/white/settings.svg";
import feedbackIcon from "../../images/white/feedback.svg";
import logoutIcon from "../../images/white/logout.svg";
import searchIcon from "../../images/white/search.svg";
import SearchInput from "../../Component/SearchInput";
import { StorageKey } from "../../Config/StorageKey";
import logoIcon from "../../images/logo.svg";
import * as Icons from "../../Icon/index";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    color: "#fff",
    background: "#12182B",
    boxShadow: "none",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      marginLeft: drawerWidth,
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  paper: {
    paddingTop: theme.spacing(8),
    display: "flex",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
      marginRight: theme.spacing(2),
    },
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    flexGrow: 1,
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
  },
  brandContainer: {
    width: `${drawerWidth}px`,
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  inputRoot: {
    color: "inherit",
    display: "flex",
    flex: 1,
  },
  inputInput: {
    flex: 1,
    background: "#F7F7FA",
    display: "flex",
    maxWidth: "70%",
    color: "#92929D",
    padding: "10px 10px 10px 50px",
    borderRadius: "10px",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  menu: {
    borderRadius: "16px",
    backgroundColor: "#2C3245",
    color: "#fff",
  },
  logo: {
    textDecoration: "none",
    display: "flex",
  },
  avatar: {
    width: "40px",
    height: "40px",
    fontSize: "1rem",
  },
  topNotification: {
    padding: "5px",
    textAlign: "center",
    background: "#D94E45",
    width: "100%",
    minHeight: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function Header(props: any) {
  const classes = useStyles();
  const history = useHistory();
  const [mobileSearch] = React.useState(props.goBack ? true : false);
  const hidden = useMediaQuery((theme: any) => theme.breakpoints.down("xs"));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: any) => {
    setAnchorEl(null);
  };

  const handleFeedback = (event: any) => {
    setAnchorEl(null);
    props.setPopup("FeedbackWidget");
  };

  const logout = () => {
    localStorage.removeItem(StorageKey.USER);
    setAnchorEl(null);
    let domain = new URL(document.location.href);
    document.location.href = domain.protocol + "//" + domain.host;
  };

  const handleChangePassword = () => {
    setAnchorEl(null);
    props.setPopup("change_password");
  }

  const getAvatarName = () => {
    return (
      props.user.first_name.charAt(0) + props.user.last_name.charAt(0)
    ).toUpperCase();
  };

  return (
    <React.Fragment>
      <CssBaseline />

      <AppBar position="fixed" className={classes.appBar}>
        
        {mobileSearch === true && hidden ? (
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => history.goBack()}
              className={classes.menuButton}
            >
              <img
                src={leftArrowIcon}
                alt="hamburger icon"
                style={{ width: "28px" }}
              />
            </IconButton>
            <Grid container>{props.header}</Grid>
          </Toolbar>
        ) : (
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={props.handleDrawerToggle}
              className={classes.menuButton}
            >
              {props.mobileOpen ? (
                <Icons.Cancel className="white-icon" />
              ) : (
                <img
                  src={hamburgerIcon}
                  alt="hamburger icon"
                  style={{ width: "28px" }}
                />
              )}
            </IconButton>

            <Box className={classes.search}>
              <Box className={classes.brandContainer}>
                <Link to={`/`} className={classes.logo}>
                  <img src={logoIcon} alt="logo" className="brand" />
                </Link>
              </Box>
              <Box
                display={{
                  xs: "none",
                  sm: "block",
                  md: "block",
                  lg: "block",
                  xl: "block",
                }}
                flexGrow={1}
              >
                {/* <SearchInput /> */}
              </Box>
              <Box
                display={{
                  xs: "block",
                  sm: "none",
                  md: "none",
                  lg: "none",
                  xl: "none",
                }}
                width="100%"
              >
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs>
                    <Link to={`/`} className={classes.logo}>
                      <img src={logoIcon} alt="logo" className="brand" />
                    </Link>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ display: "flex" }}
                      onClick={() => history.push("/search?q=")}
                    >
                      <img
                        src={searchIcon}
                        alt="search icon"
                        style={{ width: "28px" }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <div>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={"menuId"}
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Avatar
                  alt="Profile"
                  src={
                    props.user?.profile_image ? props.user?.profile_image : ""
                  }
                  className={classes.avatar}
                >
                  {getAvatarName()}
                </Avatar>
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted={false}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
                classes={{ paper: classes.menu }}
                MenuListProps={{
                  style: { paddingLeft: "10px", paddingRight: "10px" },
                }}
                PaperProps={{
                  style: {
                    transform: "translateY(40px)",
                  },
                }}
                style={{ zIndex: 1450 }}
              >
                <Link to="#" className="default">
                  <MenuItem onClick={handleChangePassword}>
                    <ListItemIcon>
                      <img src={settingIcon} alt="settings" />
                    </ListItemIcon>
                    Change Password
                  </MenuItem>
                </Link>
                <MenuItem onClick={handleFeedback}>
                  <ListItemIcon>
                    <img src={feedbackIcon} alt="feedback" />
                  </ListItemIcon>
                  Provide Feedback
                </MenuItem>
                <Divider
                  light
                  style={{
                    backgroundColor: "#787E91",
                    margin: "8px 16px 8px 16px",
                  }}
                />
                <MenuItem onClick={logout}>
                  <ListItemIcon>
                    <img src={logoutIcon} alt="logout" />
                  </ListItemIcon>
                  Sign out
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        )}
      </AppBar>
    </React.Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPopup: (name: string) => dispatch({ type: "SET_POPUP", name: name }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
